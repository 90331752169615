
.google-map-credits {
    position: relative;
    display: block;

    &:after {
        content: ' ';
        position: absolute;
        bottom: 4px;
        left: 6px;
        background: url(../images/google-logo.png) no-repeat center center;
        background-size: contain;
        width: 66px;
        height: 26px;
    }

}

.locations {
    @media screen and (min-width: $bp-940) {
        display: flex;
        flex-flow: row nowrap;
    }

    &__map {
        @media screen and (max-width: $bp-768) {
            display: none;
        }

        @media screen and (min-width: $bp-940) {
            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }

    &__listing {
        background-color: $color-brand-grey;
        padding: 25px 6% 10px;
        color: $color-brand-navy;
        @media screen and (min-width: $bp-940) {
            padding-left: 2.5%;
            padding-right: 2%;
            min-width: 530px;
            flex: 1 1 auto;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
        }


    }

}

.location-list {

     &__location {
        margin-bottom: 20px;
        font-size: 1.8rem;
        @media screen and (min-width: $bp-1230) {
            font-size: 2rem;
        }
        @media screen and (min-width: $bp-1380) {
            font-size: 2.4rem;
        }

        &:last-child {
            margin-bottom: 0;
        }

    }
}

.location {

    &__map {
        border: 1px solid darken($color-brand-grey,10%);

        @media screen and (min-width: $bp-769) {
            display: none;
        }
    }

    &__title {
        text-transform: uppercase;
        font-size: 2.2rem;
        line-height: 1.1;
        font-family: $display-font;
        color: $color-brand-navy;
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        @media screen and (min-width: $bp-1230) {
            font-size: 2.6rem;
        }
        @media screen and (min-width: $bp-1380) {
            font-size: 3rem;
        }

        &:before {
            content: '\00a0';
            display: inline-block;
            width: 25px;
            width: .8em;
            height: 25px;
            height: .8em;
            margin-right: 4px;
            background: url(../images/icon_location-pin.svg) no-repeat center center;
            background-size: contain;
            vertical-align: sub;
            margin-top: -1%;
        }

        a {
            color: inherit;
            text-decoration-color: rgba($color-brand-navy, $alpha: 0);

            &:hover,
            &:focus,
            &:active {
                color: $color-brand-red;
                text-decoration-color: rgba($color-brand-red, $alpha: 1);
            }
        }
    }
}

.trading-hours {
    background: #C7CDDA;
    color: $color-brand-navy;
    padding-bottom: 4px;

    @media screen and (min-width: $bp-940) {
        padding-bottom: 0;
        padding-right: 5%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }
    @media screen and (min-width: $bp-1230) {
        font-size: 2.4rem;
    }


    &__heading {
        background: #717c95;
        color: $color-white;
        padding: 12px 6%;
        margin-top: 0;
        @media screen and (min-width: $bp-940) {
            padding-left: 30px;
            padding-right: 30px;
            margin: 0;
        }
        @media screen and (min-width: $bp-1230) {
            padding: 16px 40px 14px;
            font-size: 3rem;
            min-height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
    p {
        padding: 0 6%;
        @media screen and (min-width: $bp-940) {
            padding: 0 20px;
            margin: 0;
        }

    }
}
