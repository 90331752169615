
$link-transition:   250ms ease-in-out;


@-webkit-keyframes growInFade {
    from {
        opacity: 0;
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }
}
@keyframes growInFade {
    from {
        opacity: 0;
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
}
