

// Brand colours

$color-brand-red: 				     #F70000;

$color-brand-red-light: 				 #FC8787;

$color-brand-navy:                   #232C35;

$color-brand-grey:                   #F2F2F2;

$color-brand-navy-light:            #565f67;


// Neutral Colours

$color-white:						#ffffff;

$color-grey-01:                     #f0f1f2;

$color-grey-02:						#d2d4d7;

$color-grey-03:						#b4b8bd;

$color-grey-04:						#969ba2;

$color-grey-05:						#858c93;

$color-grey-06:						#5d6369;

$color-grey-07:						#54595f;

$color-grey-08:						#3c3f44;

$color-grey-09:						#242629;

$color-black:						#000;


// Utility Colours

$color-utility-positive:			#0E6F22;

$color-utility-positive-subtle:		#26883A;

$color-utility-alert:				#A72D00;

$color-utility-alert-subtle:		#CD4C1D;

$color-utility-warning:				#9E0E0B;

$color-utility-warning-subtle:		#C32C2A;

$color-utility-neutral:				#0192d0;

$color-utility-neutral-subtle:		#d3f2ff;

$color-utility-selection:           #FFC57D;


// Specific item colors

$color-body-font:                   $color-black;

$color-text-link:                   $color-brand-red;
