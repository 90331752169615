
/* ---- Hero banner ---- */
.section--hero-banner {
    padding-top: 0;
    padding-bottom: 0;
}

// layout

.hero-banner-layout {

    @media screen and (min-width: $bp-940) {

        &__lgcol {
            width: 65%;
            float: left;
        }

        &__smcol {
            width: calc(100%- 65.95%);
            float: right;
        }

        & + div {
            clear: both;
        }

        @supports (display: grid) {
            display: grid;
            grid-template-columns: 65% auto;
            grid-column-gap: .95%;

            &__lgcol,
            &__smcol {
                width: auto;
            }

        }
    }

    .sticky-nav & {
        margin-top: 65px;
    }



}


// columns


.hero-banner-list {
    list-style: none;
    padding: 0;
    margin: 0;

    &.slick-dotted {

    }
}

.slick-dots {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80px;
    text-align: right;
    padding: 0 20px 20px;

    li {
        position: relative;
        width: 45px;
        height: 45px;
        padding: 0;
        cursor: pointer;
    }

    button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 45px;
        height: 45px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        overflow: hidden;

        &:hover,
        &:focus {
            outline: none;
        }

        &:hover:before,
        &:focus:before {
            opacity: 1;
        }
        &:before {
            position: absolute;
            top: calc(50% - 10px);
            left: calc(50% - 10px);
            width: 20px;
            height: 20px;
            content: '';
            background: #cdcdcd;
            border-radius: 20px;
            transition: all $link-transition;
            box-shadow: 0 0 4px rgba($color-black, .4);
        }
    }
    li.slick-active button:before,
    button:hover:before {
        background: $color-brand-red;
    }
}

.hero-banner-list__item {
    position: relative;

    &__img {
        padding-top: 70%;
        background-size: cover;
        background-position: center center;
        /* BP 1 */
        @media screen and (min-width: $bp-600) {
                padding-top: 60%;
        }
        /* BP 3 */
        @media screen and (min-width: $bp-940) {
                padding-top: 64.15%;
        }
        @media screen and (min-width: $bp-1640) {
                padding-top: 63.35%;
        }
    }

    &__text {
        position: absolute;
        bottom: 40px;
        left: 0;
        max-width: 65%;
        display: flex;
        flex-flow: row wrap;
        justify-self: flex-start;
        background-color: rgba($color-white, .7);

        @media screen and (min-width: $bp-560) {
            max-width: calc(100% - 80px);
            flex-flow: row nowrap;
        }

        &__title {
            padding: 0 30px;

            @media screen and (min-width: $bp-1230) {
                font-size: 3rem;
            }

        }
        .button {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 1 0 100%;
            padding-left: 30px;
            padding-right: 30px;

            @media screen and (min-width: $bp-560) {
                flex: 1 0 auto;
                padding-left: 20px;
                padding-right: 20px;
            }

        }
    }
}






/* ---- Hero banner video ---- */
.section--hero-banner-video {
    padding-top: 0;
    padding-bottom: 0;
    display: none;
}
.section--hero-banner-video video {
    width: 100%;
    min-width: 1280px;
    display: block;
    height: auto;
}

/* Wider than iPad BP */
@media screen and (min-width: 1025px) {
    .section--hero-banner-video {
        display: block;
    }
    .section--hero-banner-video + .section--hero-banner {
        display: none;
    }
}


/* ---- Deal promo ---- */

// This is not just in the hero banner, also product inner sidebar

.deal-promo {

    &__btns {
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;

        &__btn {
            flex: 1 1 auto;
            // min-width: 50%;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 80px;
            font-weight: bold;
            font-size: clamp(1.4rem, .5vw + 1rem, 2rem);

            @media screen and (min-width: $bp-600) {
                height: 5vw;
            }

        }
    }

    .js-video-popup {
        &:before {
            content: none;

        }
        svg {
            width: clamp(.5rem, .5vw + 3rem, 4rem);
            height: auto;
            margin-right: 1rem;
        }
    }
}

.sidebar__item--deal-promo {

    .deal-promo {

        &__btns {
            flex-flow: row wrap;

            &__btn {
                font-size: 1.6rem;
                height: 60px;
                min-width: 160px;
            }
        }

    }

}
