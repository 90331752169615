
/* Feature box */
.feature-box-list {
    list-style: none;
    padding: 0;
    margin: 20px -16px 8px;
    display: flex;
    min-width: 1px;
    flex-wrap: wrap;
}
.feature-box {
    --content-height: 50px;
    display: flex;
    flex: 0 0 50%;
    min-width: 1px;
    padding: 8px;
}
.feature-box-list--refine .feature-box {
    width: 50%;
    flex: none;
    float: left;
}
.feature-box__link {
    color: #232c35;
    text-decoration: none;
    background-color: #f1eeee;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 1px;
    position: relative;
}

.feature-box__content {
    padding: 1px 6% 0;
    min-height: 50px;
    min-height: var(--content-height);
    flex: 1 1 auto;
    background: rgba($color: #ffffff, $alpha: 0.85);
    min-width: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-box__image {
    flex: 0 0 auto;
    overflow: hidden;
    min-width: 1px;
    aspect-ratio: 502/480;
}

.feature-box__image .feature-box__image__img {
    transition: all 500ms ease;
    width: 100%;
}
.feature-box__hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    text-align: center;
    color: $color-black;
    opacity: 0;

    transition: all 300ms ease;
}
.feature-box__hover__text__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: var(--content-height);
    padding: 8px 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $bp-1380) { /* 1380px */
        padding-left: 30px;
        padding-right: 30px;
    }

}
.feature-box__hover__descript {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
}
.feature-box__hover__subtitle {
    margin: 16px 0 12px;
    font-weight: 400;
    font-size: 2.2rem;

    .feature-box__hover--no-desc & {
        font-size: 3rem;
    }
}
.feature-box__hover__button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 50px;
    min-height: var(--content-height);
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-brand-red !important;
}

.feature-box__title {
    margin: 0;
    display: block;
    text-transform: uppercase;
    font-family: $display-font;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 1.1;
}
.feature-box__title:after {
    color: $color-brand-red;
    font-size: 13px;
    margin: 0 0 0 4px;
}
.feature-box__no-results {
    padding-bottom: 20px;
}

/* Hover */
.feature-box__link:hover,
.feature-box__link:focus,
.feature-box__link:active {
    color: #000;
}
.feature-box__link:hover {
    background-color: #e8e4e4;
}

.feature-box__link:hover .feature-box__image__img {
    transform: scale(1.1);
    opacity: 0.9;
}


/* BP 1 */
@media screen and (min-width: $bp-600) {
    .feature-box-list {
        margin-top: 30px;
    }

    .feature-box-list--5 .feature-box,
    .feature-box-list--3 .feature-box,
    .feature-box-list--6 .feature-box {
        flex: 0 0 33.33%;
    }
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
    .feature-box__title {
        font-size: 1.8rem;
    }
    .feature-box__title:after {
        font-size: 15px;
    }
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
    .feature-box {
        --content-height: 75px;
    }
    .feature-box__content {
        padding-left: 20px;
        padding-right: 20px;
        min-height: var(--content-height);
    }
    .feature-box__hover__button {
        min-height: var(--content-height);
    }
    .feature-box__title {
        font-size: 2rem;
    }

    .feature-box-list {
        margin-top: 40px;
    }
    .feature-box-list--refine .feature-box {
        width: 33.333333%;
        flex: none;
    }
    .feature-box__no-results {
        padding-bottom: 50px;
    }
}

/* Wider than iPad BP */
@media screen and (min-width: $bp-1025) {
    .feature-box__link:hover .feature-box__hover__text__wrapper {
        animation: growInFade 500ms ease;
        animation-fill-mode: forwards;
    }
    .feature-box__link:hover .feature-box__hover {
        opacity: 1;
    }
}

/* Max BP 1 */
@media screen and (max-width: $bp-1229) {
    .feature-box__hover__button.button {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
    .feature-box-list {
        margin-top: 50px;
    }
    .feature-box__content {
        padding-left: 30px;
        padding-right: 30px;
    }


    .feature-box {
        flex: 0 0 25%;
    }
    .feature-box-list--5 .feature-box {
        flex: 0 0 20%;
    }
    .col-lg-8 .mainbar .feature-box {
        flex: 0 0 50%;
    }
    .feature-box-list--refine .feature-box {
        width: 25%;
        flex: none;
    }
    .feature-box-list--3 .feature-box,
    .feature-box-list--6 .feature-box {
        font-size: 2.4rem;
    }

    .feature-box-list--3 .feature-box__title,
    .feature-box-list--6 .feature-box__title,
    .feature-box-list--3 .feature-box__hover__subtitle,
    .feature-box-list--6 .feature-box__hover__subtitle {
        font-size: 3rem;
    }
    /* 2019 hack:
    not currently used but could be useful in future
        make an extra feature box to compliment the five main products so it can break evenly on smaller devices */
    // .feature-box-list--6 .feature-box {
    //     flex: 0 0 20%;
    // }
    // .feature-box-list--6 .feature-box:last-child {
    //     display: none;
    // }

}
