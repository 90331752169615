
// base component

.media-object {
    --mo-outside-spacing: 40px;
    --mo-inside-spacing: 24px;
    --mo-quarter-inside-spacing: calc(var(--mo-inside-spacing)/2);
    clear: both;

    @media screen and (min-width: $bp-769) {
        --mo-outside-spacing: 60px;
        --mo-inside-spacing: 44px;

        display: grid;
        grid-template-columns: calc(50% - var(--mo-quarter-inside-spacing)) auto;

        &:nth-child(2n+0) {
            grid-template-columns: auto calc(50% - var(--mo-quarter-inside-spacing));
            justify-items: end;

            .media-object__img {
                grid-column-start: 2;
            }

            .media-object__text {
                grid-column-start: 1;
                grid-row-start: 1;
                padding: 30px var(--mo-inside-spacing) 30px var(--mo-outside-spacing);

            }

            .media-object__heading:before {
                left: 0;
                right: calc(-1 * var(--mo-inside-spacing));
            }


        }
    }
    @media screen and (min-width: $bp-1230) {
        --mo-outside-spacing: 80px;
        --mo-inside-spacing: 60px;
    }


    &__img {
        @media screen and (min-width: $bp-769) {
            float: left;
            width: 45%;

            @supports (display: grid) {
                width: 100%;
            }
        }

    }

    &__text {
        font-size: 1.8rem;
        padding: 24px;
        padding: 24px var(--mo-outside-spacing) 24px var(--mo-inside-spacing);

        display: flex;
        flex-flow: column nowrap;
        justify-content: center;

        @media screen and (min-width: $bp-769) {
            float: right;
            width: 55%;

            @supports (display: grid) {
                width: 100%;
                max-width: 70ch;
            }
        }

        @media screen and (min-width: $bp-940) {
            font-size: 2rem;
        }

        @media screen and (min-width: $bp-1230) {
            font-size: 2.4rem;
        }

        p {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 1em;
        }

    }

    &__heading {
        margin-top: 0;
        padding-bottom: 16px;
        position: relative;
        font-size: 2.4rem;

        @media screen and (min-width: $bp-940) {
            font-size: 2.8rem;
        }
        @media screen and (min-width: $bp-1230) {
            font-size: 3.6rem;

        }


        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: calc(-1 * var(--mo-inside-spacing));
            height: 1px;
            background-color: $color-grey-04;
        }
    }
}

.media-oject-list {



    @media screen and (min-width: $bp-769) {

    }
}

