/* ------------------------------------------------------
Table of Contents for search based navigation

**** Viewports

**** Box Model Reset

**** Animations

**** Typography
---- Fonts
---- Headings
---- Lists
---- Breadcrumbs
---- Blockquotes
---- HR
---- Text level elements
---- Links

**** Design Patterns
---- Buttons
---- Expando
---- Highlight
---- Site Messages

**** Images

**** Forms
---- Base styles
---- Inline
---- White
---- Small
---- Large
---- Disabled
---- Caps
---- Placeholders
---- Fieldset
---- Labels and legends
---- Required
---- Field helpers
---- Field errors
---- Text area
---- Range
---- Selects
---- Checkboxes and radio buttons
---- Color picker
---- Money
---- Time pickers
---- Date pickers
---- File upload area
---- File selector
---- Autocomplete
---- Lightweight elements
---- TinyMCE tweaks
---- Responsiveness
---- Submit Bar
---- Search Form
---- Search pagination
---- Captcha

**** Tables
---- Content tables
---- Responsive table
---- No Styles Table

**** Helper classes
---- Clearfix
---- Hiding classes
---- Hide/show
---- Offset
---- Pseudo elements
---- Floats and positioning
---- Grid system

**** Template Layout
---- simple content alignment classes
---- Sections
---- Blocks
---- Background colours
---- Content Box
---- Subnav
---- Header
---- Nav
---- Hero banner
---- Hero banner video
---- Info banner
---- Inner page banner
---- home banner alert
---- Deal promo
---- Content
---- Thumb gallery
---- Image gallery widget
---- Content slider
---- Pre footer
---- Partners section
---- Products
---- About
---- Video
---- icon-card list
---- Footer
---- Promotion gallery
---- Magnific Popup

**** Widgets
---- Related Links
---- Simple Image Gallery
---- Children Page Gallery
---- Share links
---- Sponsors module
---- Order Form
---- Videos
---- reCAPTCHA

**** Print styles

---- TinyMCE Templates

------------------------------------------------------ */



/* ------------------------------------------------------
**** Viewports
------------------------------------------------------ */

@-webkit-viewport {
	width: device-width;
	zoom: 1.0;
}
@-moz-viewport {
	width: device-width;
	zoom: 1.0;
}
@-ms-viewport {
	width: device-width;
	zoom: 1.0;
}
@-o-viewport {
	width: device-width;
	zoom: 1.0;
}
@viewport {
	width: device-width;
	zoom: 1.0;
}


/* ---- Global Variables ---- */

@import "06-abstracts/colors";

@import "06-abstracts/fonts";

@import "06-abstracts/breakpoints";


/* ------------------------------------------------------
**** Box Model Reset
------------------------------------------------------ */

html {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}
*, *:before, *:after {
	-webkit-box-sizing: inherit;
	        box-sizing: inherit;
}

/* ------------------------------------------------------
**** Animations
------------------------------------------------------ */

@import "06-abstracts/animation";

/* ------------------------------------------------------
**** Typography
------------------------------------------------------ */


.icon-before:before,
.icon-after:after {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
}


.icon-before.icon-sale:before,
.icon-after.icon-sale:after {
	content: "\e91b";
}
.icon-before.icon-phone:before,
.icon-after.icon-phone:after {
	content: "\e91a";
}
.icon-before.icon-home:before,
.icon-after.icon-home:after {
	content: "\e900";
}
.icon-before.icon-arrow-down:before,
.icon-after.icon-arrow-down:after {
	content: "\e901";
}
.icon-before.icon-arrow-left:before,
.icon-after.icon-arrow-left:after {
	content: "\e902";
}
.icon-before.icon-arrow-right:before,
.icon-after.icon-arrow-right:after {
	content: "\e903";
}
.icon-before.icon-arrow-up:before,
.icon-after.icon-arrow-up:after {
	content: "\e905";
}
.icon-before.icon-chat:before,
.icon-after.icon-chat:after {
	content: "\e913";
}
.icon-before.icon-check:before,
.icon-after.icon-check:after {
	content: "\e915";
}
.icon-before.icon-delete:before,
.icon-after.icon-delete:after {
	content: "\e912";
}
.icon-before.icon-download:before,
.icon-after.icon-download:after {
	content: "\e916";
}
.icon-before.icon-playhead:before,
.icon-after.icon-playhead:after {
	content: "\e917";
}
.icon-before.icon-tools:before,
.icon-after.icon-tools:after {
	content: "\e918";
}
.icon-before.icon-cross:before,
.icon-after.icon-cross:after {
	content: "\e904";
}
.icon-before.icon-group:before,
.icon-after.icon-group:after {
	content: "\e919";
}
.icon-before.icon-quote-start:before,
.icon-after.icon-quote-start:after {
	content: "\e908";
}
.icon-before.icon-quote-end:before,
.icon-after.icon-quote-end:after {
	content: "\e907";
}
.icon-before.icon-search:before,
.icon-after.icon-search:after {
	content: "\e909";
}
.icon-before.icon-star-half:before,
.icon-after.icon-star-half:after {
	content: "\e90a";
}
.icon-before.icon-star:before,
.icon-after.icon-star:after {
	content: "\e90b";
}
.icon-before.icon-location:before,
.icon-after.icon-location:after {
	content: "\e914";
}
.icon-before.icon-zoom-in:before,
.icon-after.icon-zoom-in:after {
	content: "\e90e";
}
.icon-before.icon-zoom-out:before,
.icon-after.icon-zoom-out:after {
	content: "\e90f";
}
.icon-before.icon-facebook:before,
.icon-after.icon-facebook:after {
	content: "\e906";
}
.icon-before.icon-instagram:before,
.icon-after.icon-instagram:after {
	content: "\e90c";
}
.icon-before.icon-pinterest:before,
.icon-after.icon-pinterest:after {
	content: "\e90d";
}
.icon-before.icon-twitter:before,
.icon-after.icon-twitter:after {
	content: "\e910";
}
.icon-before.icon-youtube:before,
.icon-after.icon-youtube:after {
	content: "\e911";
}

.icon-sale--product-special:before,
.icon-sale--product-special:after {
	font-size: 45px;
	margin-left: -15px;
	position: relative;
	top: -2px;
	vertical-align: middle;
}

html {
	font-size: 62.5%;
}

body {
	line-height: 1.5;
	font-size: 1.6em;
	color: $color-body-font;
	font-family: $primary-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: auto;

	@media screen and (min-width: $bp-1380) { /* 1380px */
		font-size: 2rem;
		max-width: 90em; /* 1800px */
	}

}


/* ---- Headings ---- */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	line-height: 1.1;
	margin: 2rem 0 1.5rem 0;
	font-family: $display-font;
	font-weight: 300;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
	font-weight: normal;
	line-height: 1;
	color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
	margin-top: 3rem;
}


h1, .h1 {
	line-height: 1;
	font-size: 2.8rem;
	text-transform: uppercase;
	color: $color-brand-navy;
}
h1:after, .h1:after {
	content: " ";
	height: 3px;
	background: $color-brand-red;
	display: block;
	margin: 6px 0 0 -150px;
	width: 200px;
}

h2, .h2 {
	font-size: 2.2rem;
	text-transform: uppercase;
}

h3, .h3 {
	font-size: 2rem;
	text-transform: uppercase;
	margin: 1.4rem 0 1rem;
}

h4, .h4 {
	font-size: 1.9rem;
	margin: 1.4rem 0 1rem;
}

h5, .h5 {
	font-size: 1.8rem;
	margin: 1.4rem 0 1rem;
}

h6, .h6 {
	font-size: 1.7rem;
	margin: 1.4rem 0 1rem;
}


/* BP 2 */
@media screen and (min-width: $bp-769) {
	h1, .h1 {
		font-size: 3.6rem;
	}
	h1:after, .h1:after {
		margin-top: 11px;
		width: 220px;
	}

	h2, .h2 {
		font-size: 2.4rem;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	h1, .h1 {
		font-size: 4.2rem;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	h1, .h1 {
		font-size: 5.6rem;
	}
}
@media screen and (min-width: $bp-1380) { /* 1380px */
	h1:after, .h1:after {
		width: 200px;
		margin-left: -100px;
	}

	h2, .h2 {
		font-size: 3.6rem;
	}
	h3, .h3 {
		font-size: 2.6rem;
	}

	h4, .h4 {
		font-size: 2.2rem;
	}

	h5, .h5 {
		font-size: 2.1rem;
	}

	h6, .h6 {
		font-size: 2rem;
	}
}



.heading-inline {
	display: inline;
	float: left;
	padding: 0;
}


/* Subtitle */
.subtitle {
	font-size: 1.8rem;
	font-family: $display-font;
	font-weight: 300;
	text-transform: uppercase;
	line-height: 1.2;
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.subtitle {
		font-size: 2rem;
	}
}


p,
ol,
ul,
dl,
address {
	margin: 0 0 1.5rem;
}

small {
	font-size: 1.28rem;
}


/* ---- Lists ---- */
ul,
ol {
	padding: 0 0 0 2rem;
}

li ul,
li ol {
	margin: 1rem 0;
}

ul.inline,
ol.inline {
	list-style-type: none;
	margin-left: 0;
}
ul.inline > li,
ol.inline > li {
	display: inline-block;
	padding-left: 12px;
	padding-right: 12px;
}
ul {
	list-style-type: square;
}

/* List style 1 */
.list-style1,
.list-style1 ul,
.product-view__expandos .expando > ul {
	list-style: none;
	padding: 0;
	margin-bottom: 2rem;
}
.list-style1--footer a {
	color: #AEB5BD;
}
.list-style1--footer a:hover,
.list-style1--footer a:focus {
	color: $color-white;
}
.list-style1--footer .h3 {
	color: $color-white;
}
.list-style1--footer .h3:hover,
.list-style1--footer .h3:focus {
	color: #AEB5BD;
}

.list-style1--footer ul {
	margin-bottom: 0;
}
.list-style1 a,
.product-view__expandos .expando ul a {
	text-decoration: none;
	display: block;
}
.list-style1 p:first-child,
.product-view__expandos .expando ul p:first-child {
	margin-bottom: 5px;
}

/* Depth 1 */
.list-style1 > li,
.product-view__expandos .expando > ul > li {
	padding: 6px 0 1px;
	line-height: 1.2;
}
.product-view__expandos .expando ul ul li {
	line-height: 1.5;
}

/* Depth 2 */
.list-style1 > li > ul {
	margin-top: 3px;
}
.list-style1 > li > ul > li > a {
	text-transform: lowercase;
	padding: 5px 0;
}
.list-style1 > li > ul > li > a:before {
	content: "\003e\00a0";
}

/* -- Caps -- */
.list-style1--caps {
	text-transform: uppercase;
	font-family: $display-font;
	font-weight: 300;
	font-size: 1.7rem;
}

/* -- Icons -- */
.list-style1--icons li:before,
.product-view__expandos .expando > ul > li:before {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	color: $color-brand-red;
	margin: 1px 6px 0 -27px;
	display: block;
	float: left;
}
.list-style1--icons li,
.product-view__expandos .expando > ul > li {
	margin-left: 24px;
}

/* Check */
.list-style1--icons--check li:before,
.product-view__expandos .expando > ul > li:before {
	content: "\e915";
}

/* BP 1 */
@media screen and (min-width: $bp-600) {

}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.list-style1 > li,
	.product-view__expandos .expando > ul > li {
		padding: 10px 0 3px;
	}
	.list-style1.list-style1--caps > li {
		font-size: 2rem;
	}

	.list-style1--footer > li {
		padding: 0 0 10px;
	}

	.list-style1--footer > li > a:last-child {
		margin-bottom: 0;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {

}

/* BP 4 */
@media screen and (min-width: $bp-1230) {

}

/* ---- Breadcrumbs ---- */
.breadcrumb {
	list-style: none;
	margin: 1.5rem 0 0.7rem;
	padding: 0;
	font-size: 1.4rem;
	color: #c2bdbd;
	font-family: $alternate-font;
	text-transform: uppercase;
}
.breadcrumb + h1 {
	margin-top: 0;
	margin-bottom: 30px;
}
.breadcrumb > li {
	display: inline-block;
}
.breadcrumb > li:before {
	content: "\00a0/\00a0";
	display: inline-block;
	margin: 0 2px;
}
.breadcrumb > li:first-child:before {
	content: none;
}
.breadcrumb a {
	color: $color-brand-navy-light;
	text-decoration: none;
}
.breadcrumb a:hover,
.breadcrumb a:focus,
.breadcrumb a:active {
	color: $color-brand-navy;
}


/* ---- Blockquotes ---- */
blockquote {
	margin: 1.5rem 0;
	padding: 30px 30px;
	background: #f1eeee;
	font-style: italic;
	position: relative;
	clear: both;
}

blockquote :first-child {
	margin-top: 0;
}
blockquote :last-child {
	margin-bottom: 0;
}

blockquote:before,
blockquote:after {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	position: absolute;
	font-size: 3rem;
	display: block;
	color: #9ba7b2;
	font-family: $primary-font;
}

blockquote:before {
	content: "\e908";
	top: 3px;
	left: 7px;
}

blockquote:after {
	content: "\e907";
	bottom: 3px;
	right: 7px;
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	blockquote {
		padding: 45px 30px;
	}

	.blockquote--left {
		float: left;
		width: 45%;
		margin-left: 0px;
		margin-right: 20px;
		clear: right;
	}
	.blockquote--right {
		float: right;
		width: 45%;
		margin-left: 20px;
		margin-right: 0px;
		clear: left;
	}
	blockquote:before,
	blockquote:after {
		font-size: 4rem;
	}
	blockquote:before {
		top: 6px;
		left: 10px;
	}

	blockquote:after {
		bottom: 6px;
		right: 10px;
	}

}


/* ---- HR ---- */
hr {
	-webkit-box-sizing: content-box;
	        box-sizing: content-box;
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 2em 0;
	padding: 0;
}


/* ---- Text level elements ---- */
abbr[title] {
	border-bottom: 1px dotted #c6bfbf;
	cursor: help;
}

b, strong {
	font-weight: bold;
}

dfn {
	font-style: italic;
}

ins {
	background-color: #f6f6f6;
	color: #473f3f;
	text-decoration: none;
}

mark {
	background-color: #f6f6f6;
	color: #473f3f;
	font-style: italic;
	font-weight: bold;
}

pre,
code,
kbd,
samp {
	font-family: 'Monaco', 'Courier New', monospace;
	color: #484040;
	background: #efefef;
	background: rgba(0, 0, 0, .07);
	padding: 0 2px;
	margin: 0 -2px;
}

pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -.5em;
}

sub {
	bottom: -.25em;
}


/* ---- Links ---- */
a {
	color: $color-brand-red;
	word-break: break-word;
	transition: $link-transition;
}

a:hover,
a:active,
a:focus {
	color: #d02c44;
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
	outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
	outline: 0;
}

/* Include file type and size in document links */
a.document::after {
	content: " (" attr(data-ext) " " attr(data-size) ")";
}

.arrow-link {
	text-decoration: none;
	font-weight: bold;
	padding-right: 6px;
}

.arrow-link:hover {
	padding-left: 6px;
	padding-right: 0px;
}

.arrow-link:focus,
.arrow-link:active {
	text-decoration: underline;
}

.arrow-link:after {
	content: "\00a0\003e";
}


/* ------------------------------------------------------
**** Design Patterns
------------------------------------------------------ */

/* ---- Buttons ---- */
.button {
	display: inline-block;
	margin-bottom: 0;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	font-weight: normal;
	line-height: 1;
	color: #fff;
	text-decoration: none;
	border-radius: 0;
	border: none;
	text-transform: lowercase;
	outline: none;
	position: relative;
	font-family: $alternate-font;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 1.2;
	transition: all 200ms ease-in-out;
}
.button:before {
	content: "\003e\00a0";
}


/* Buttons with icons */
.button.icon-before:before,
.button.icon-after:after {
	font-size: 12px;
}
.button.icon-before:before {
	margin-right: 7px;
}
.button.icon-after:after {
	margin-left: 7px;
}

/* Save */
.button.icon-save.icon-before:before,
.button.icon-save.icon-after:after {
	font-size: 13px;
}

/* Buttons with only icons */
.button.button-icon.icon-before:before,
.button.button-icon.icon-after:after {
	margin: 0;
}

/* Small */
.button.button-icon.button-small {
	padding: 10px 9px 8px;
}
.button.button-icon.button-small.icon-before:before,
.button.button-icon.button-small.icon-after:after {
	font-size: 14px;
}


/* Regular (default) */
.button.button-icon,
.button.button-icon.button-regular {
	padding: 11px 11px 9px;
}
.button.button-icon.icon-before:before,
.button.button-icon.icon-after:after,
.button.button-icon.button-regular.icon-before:before,
.button.button-icon.button-regular.icon-after:after {
	font-size: 21px;
}

/* Large */
.button.button-icon.button-large {
	padding: 13px 13px 9px;
}
.button.button-icon.button-large.icon-before:before,
.button.button-icon.button-large.icon-after:after {
	font-size: 25px;
}


/* Small */
.button,
.button.button-small,
.field-element--button.field-element--small .button {
	font-size: 1.4rem;
	padding: 9px 8px;
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	/* Regular */
	.button,
	.button-regular {
		padding: 17px 19px 16px;
		font-size: 1.4rem;
	}
}


/* BP 3 */
@media screen and (min-width: $bp-940) {
	/* Regular */
	.button,
	.button-regular {
		padding: 20px 19px 19px;
	}
	/* Large */
	.button.button-large,
	.field-element--button.field-element--large .button {
		padding: 22px 28px;
		font-size: 1.6rem;
	}
}

@media screen and (min-width: $bp-1380) {
	/* Regular */
	.button,
	.button-regular {
		padding-left: 32px;
		padding-right: 32px;
		font-size: 2rem;
	}
	/* Large */
	.button.button-large,
	.field-element--button.field-element--large .button {
		padding-left: 32px;
		padding-right: 32px;
		font-size: 2.4rem;
	}
}



/* Red (default) */
.button,
.button.button-red {
	background-color: $color-brand-red;
	color: #fff;
}

.button:hover,
.button.button-red:hover,
.button:focus,
.button.button-red:focus {
	background-color: darken($color-brand-red, 8%);
	color: #fff !important;
}

/* White */
.button.button-white {
	background-color: $color-white;
	color: $color-brand-red;
}

.button.button-white:hover,
.button.button-white:focus {
	background-color: $color-brand-navy;
	color: #fff !important;
}

/* Grey */
.button.button-grey {
	background-color: #c2bdbd;
	color: #fff;
}
.button.button-grey.icon-before:before,
.button.button-grey.icon-after:after {
	color: #81848A;
}
.button.button-grey:hover,
.button.button-grey:focus {
	background-color: #cecaca;
	color: #24292f;
}

.button-block {
	display: block;
	width: 100%;
}

/* Navy */
.button.button-navy {
	background-color: #232c35;
	color: #fff;
}
.button.button-navy.icon-before:before,
.button.button-navy.icon-after:after {
	color: #ffffff;
}
.button.button-navy:hover,
.button.button-navy:focus {
	background-color: #4d5862;
	color: #232c35;
}

.button-block {
	display: block;
	width: 100%;
}

/* Hover/unhover states */
.button-hover-state {
	display: none;
}
.button:hover .button-hover-state {
	display: inline;
}
.button:hover .button-unhover-state {
	display: none;
}

/* Disabled/unavailable button style */
.button[disabled],
.button-disabled,
.button-disabled:hover,
.button-disabled:focus,
.button-disabled:active {
	border: none;
	background-image: none;
	opacity: 0.4;
	cursor: default;
	-webkit-box-shadow: none;
	        box-shadow: none;
}

/* Gets the buttons to line up with form inputs when you want them to */
.button.inline {
	margin-bottom: 0.75em;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

/* Inline buttons */
.inline-buttons .button {
	margin-right: 8px;
	float: left;
}
.inline-buttons .button:last-child {
	margin-right: 0;
}

/* right */
.inline-buttons.inline-buttons-right {
	text-align: right;
}
.inline-buttons.inline-buttons-right .button {
	margin-right: 0;
	margin-left: 8px
}
.inline-buttons.inline-buttons-right .button:first-child {
	margin-left: 0;
}

/* Stacked buttons */
.stacked-buttons .button {
	margin: 0 0 4px;
}
.stacked-buttons .button:last-child {
	margin-bottom: 0;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.stacked-buttons .button {
		margin-bottom: 5px;
	}
}

/* blog refine btns */
.blog-refine-bar p.blog-refine-bar__text {
	display: block;
	margin: 2rem 0 0.5rem 0;
}

.blog-refine-bar__button {
	padding: 4px 10px 4px 10px;
	margin-bottom: 5px;
}


/* ---- Widget Post Hub ---- */
.widget-BlogPostHub .widget-title {
	line-height: 1.5;
	font-size: 1.6rem;
	color: #232c35;
	font-family: $primary-font;
	text-transform: none;
	margin: 2rem 0 0.5rem 0;
}
.js-post-hub-filterlist .field-element--dropdown {
	display: none;
}

.widget-BlogPostHub .post-hub-filterlist__button {
	padding: 4px 10px 4px 10px;
	margin-bottom: 5px;
}

.post-hub-filterlist__button.button-black {
	background-color: #000;
}

.post-hub__item {
	margin: 0 20px 20px;
	background-color: #f4f4f4;
	padding: 20px;
}
.post-hub__item.col-xs-12 {
	max-width: calc(100% - 40px);
}

.widget-BlogPostHub .post-hub__footer p {
	margin-bottom: 0;
}

.widget-BlogPostHub .post-hub__footer a.button{
	padding: 10px 18px 10px 16px;
}

@media screen and (min-width: $bp-769) {
	.post-hub__item {
		margin: 0 10px 20px;
	}
	.post-hub__item.col-sm-6 {
		max-width: calc(50% - 20px)
	}
}

@media screen and (min-width: $bp-940) {
	.post-hub__item.col-md-4 {
		max-width: calc(33.33% - 20px);
	}
}

@media screen and (min-width: $bp-600) {
	.post-hub-filterlist {
		text-align: left;
	}
	.js-post-hub-filterlist .field-element--dropdown {
		margin-bottom: 0;
	}
}


/* ---- Expando ---- */
.expando {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.expando > *:last-of-type {
    margin-bottom: 0;
}

/* - Open button - */
.expando-open {
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;
    padding: 6px 0 3px 40px;
    color: #232c35;
    position: relative;
    cursor: pointer;
    display: block;
    margin: 0 0 12px;
    text-transform: uppercase;
}
.expando-open__icon {
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #f1eeee;
    width: 26px;
    height: 26px;
    -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
}
.expando-open__icon:before,
.expando-open__icon:after {
    content: " ";
    width: 16px;
    height: 2px;
    background-color: #232c35;
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: -webkit-transform 200ms ease;
    transition: -webkit-transform 200ms ease;
    -o-transition: transform 200ms ease;
    transition: transform 200ms ease;
    transition: transform 200ms ease, -webkit-transform 200ms ease;
}
.expando-open__icon:after {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}

/* Hover */
.expando-open:hover,
.expando-open:focus {
    color: inherit;
    text-decoration: none;
}

.expando-open:hover .expando-open__icon {
    background-color: #e6e1e1;
}
.expando-open:hover .expando-open__icon:before,
.expando-open:hover .expando-open__icon:after {
    background-color: #222932;
}

/* - Close button - */
.expando__close {
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;
    text-indent: -9999px;
    padding: 0;
    height: 26px;
    width: 26px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.expando__close:before,
.expando__close:after {
    content: " ";
    width: 14px;
    height: 2px;
    background-color: #778294;
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: -webkit-transform 200ms ease;
    transition: -webkit-transform 200ms ease;
    -o-transition: transform 200ms ease;
    transition: transform 200ms ease;
    transition: transform 200ms ease, -webkit-transform 200ms ease;
}
.expando__close:before {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.expando__close:after {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
}

/* Hover */
.expando__close:hover:before,
.expando__close:hover:after {
    background-color: #222932;
}


/* - Expanded - */
.expando.expando--expanded {
    clip: auto;
    height: auto;
    margin: 20px 0 30px;
    overflow: visible;
    position: relative;
    width: auto;
    padding: 25px 45px;
    background-color: #f0f0f3;
    -webkit-animation: fadeInDown 200ms ease;
            animation: fadeInDown 200ms ease;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
.expando-open--expanded .expando-open__icon:before,
.expando-open--expanded .expando-open__icon:after {
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
}

/* - Heading - */
.expando-open.expando-open--heading {
	font-family: $display-font;
	font-weight: 300;
	border-top: 3px solid #f2efef;
	padding: 17px 40px 4px 0;
}
.expando-open.expando-open--heading .expando-open__icon {
    left: auto;
    right: 0;
    background-color: transparent;
    width: 26px;
    height: 26px;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    top: 15px;
}
.expando-open.expando-open--heading:hover .expando-open__icon {
    background-color: #f1eeee;
}
.expando-open--heading + .expando.expando--expanded {
    padding: 0;
    background-color: transparent;
}
.expando-open--heading + .expando .expando__close {
	display: none;
}

@media screen and (min-width: $bp-769) {
	.expando-open.expando-open--heading,
	.expando-open--heading + .expando.expando--expanded {
		padding-left: 25px;
	}
}


/* ---- Highlight ---- */
.highlight,
.highlight--right,
.highlight--left {
	margin: 2.5rem 0;
	padding: 20px 20px 10px;
	background: #f1eeee;
	clear: both;
}

@media screen and (min-width: 480px) {
	.highlight,
	.highlight--right,
	.highlight--left {
		padding: 25px 30px 15px;
	}
}
@media screen and (min-width: $bp-940) {

	.highlight--left {
		float: left;
		width: 45%;
		margin-right: 20px;
		clear: right;
	}

	.highlight--right {
		float: right;
		width: 45%;
		margin-left: 20px;
		clear: left;
	}
}



/* ---- Site Messages ---- */
ul.messages {
	list-style: none;
	margin: 16px 0 25px;
	padding: 0;
}
ul.messages li {
	margin: 0 0 15px;
	padding: 10px 20px;
	vertical-align: bottom;
	text-align: left;
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 1.2em;
}
ul.messages .error {
	background: #E04D4D;
	border-left: 4px solid #B72323;
	color: #FFF !important;
}
ul.messages .confirm {
	color: #FFF !important;
	background: #6DBB5A;
	border-left: 4px solid #439230;
}
ul.messages a {
	color: #FFF;
}


/* ------------------------------------------------------
**** Images
------------------------------------------------------ */

img {
	vertical-align: middle;
	max-width: 100%;
	height: auto;
}

/* ------------------------------------------------------
**** Forms
------------------------------------------------------ */

/* ---- Base styles ---- */
.field-element {
	margin-bottom: 21px;
}

.field-element:before,
.field-element:after {
	content: " ";
	display: table;
}

.field-element:after {
	clear: both;
}

.field-input .textbox,
.field-input select {
	display: block;
	width: 100%;
	outline: none;
	line-height: normal;
	padding: 13px 15px;
	-webkit-appearance: none;
}
.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
	font-size: 1.6rem;
	line-height: 1.3;
	border: 1px solid #e6e1e1;
	border-radius: 0;
	background-color: #f1eeee;
}

/* ---- Inline ---- */
.field-elements-inline:before,
.field-elements-inline:after {
	content: " ";
	display: table;
}

.field-elements-inline:after {
	clear: both;
}
.field-elements-inline .field-element {
	float: left;
	margin-right: 1%;
}
.field-elements-inline .field-element:last-child {
	margin-right: 0;
}

/* Button */
.field-elements-inline .field-element--button {
	margin-top: 29px;
}
.field-elements-inline .field-element--button.field-element--inline-no-label {
	margin-top: 0;
}

/* Add top margin to make empty space for margins */
.field-element--inline-with-label {
	margin-top: 29px;
}

/* ---- White ---- */
.field-element.field-element--white .field-input .textbox,
.field-element.field-element--white .field-input select,
.field-element--white.field-element--fileselector .fs-preview-wrapper {
	background-color: #fff;
}


/* ---- Small ---- */
.field-element--small .field-input .textbox,
.field-element--small .field-input select {
	font-size: 1.4rem;
	padding: 6px 20px 8px;
}

/* ---- Large ---- */
.field-element--large .field-input .textbox,
.field-element--large .field-input select {
	font-size: 1.8rem;
	padding: 18px 25px 20px;
}

@media screen and (max-width: 768px) {
	.field-element {
		margin-bottom: 13px;
	}
}

/* ---- Disabled ---- */
.field-element--disabled {
	opacity: 0.6;
	cursor: not-allowed;
}
.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled] {
	cursor: not-allowed;
}

/* ---- Caps ---- */
.field-element--caps .textbox,
.field-element--caps select {
	text-transform: uppercase;
}

/* ---- Placeholders ---- */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	color: #a29999;
	text-transform: uppercase;
}
::-moz-placeholder { /* Firefox 19+ */
	color: #a29999;
	text-transform: uppercase;
}
:-ms-input-placeholder { /* IE 10+ */
	color: #a29999;
	text-transform: uppercase;
}
:-moz-placeholder { /* Firefox 18- */
	color: #a29999;
	text-transform: uppercase;
}
.field-element--dropdown--placeholder .field-input select {
	color: #a29999;
	text-transform: uppercase;
}

/* ---- Fieldset ---- */
.field-element fieldset {
	border: none;
	padding: 0;
	margin: 0;
}


/* ---- Labels and legends ---- */
.field-label,
.fieldset__legend {
	font-size: 1.6rem;
	margin-bottom: 10px;
	line-height: 1.2;
	text-transform: uppercase;
	font-family: $alternate-font;
}

/* Instead of h3s */
.label.heading {
	margin: 30px 0px 20px;
	padding: 8px 8px 8px 0px;
	border-bottom: 1px solid #E2E2E2;
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}


/* ---- Required ---- */
.field-label__required {
	text-indent: -9999px;
	line-height: 0;
	display: inline-block;
}

.field-label__required:after {
	content: "*";
	text-indent: 0;
	display: block;
	line-height: inherit;
}


/* ---- Field helpers ---- */
.field-helper {
	font-weight: 400;
	font-size: 1.3rem;
	margin-bottom: 1em;
}

.field-label .field-helper {
	margin-top: 0.3em;
	margin-bottom: 0;
}


/* ---- Field errors ---- */
.field-element.field-element--error  .field-input .textbox,
.field-element.field-element--error  .field-input select {
	background-color: #f7eaec;
}
.field-error__list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.field-error__list__item {
	color: #fff;
	background: #E04D4D;
	padding: 10px 20px;
	margin: 11px 0;
	border-left: 6px solid #b72323;
}

/* ---- Text area ---- */
textarea {
	resize: vertical;
}

/* ---- Range ---- */
.field-element--range .field-input .textbox {
	-webkit-appearance: slider-horizontal;
	background: none;
	padding: 0;
	border-radius: 0;
	border: none;
	background: none !important;
}
input[type=range]::-moz-focus-outer {
	border: 0;
}


/* ---- Selects ---- */
.field-element .field-input select {
	-moz-appearance: none;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -253px right -102px;
	padding-right: 41px;
}
.field-element .field-input select[multiple] {
	background-image: none;
	padding-right: 20px;
}

.field-element select::-ms-expand,
.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
	display: none;
}

/* Multiple selects */
.field-element.field-element--select.field-element--select--multiple select {
	padding: 8px 8px;
	background-image: none;
}

.field-element.field-element--select.field-element--select--multiple option {
	padding: 6px 10px;
}

/* Small */
.field-element--small .field-input select {
	background-position: top -257px right -104px;
}
/* Large */
.field-element--large .field-input select {
	background-position: top -245px right -103px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 940px) {
	.field-element .field-input select,
	.field-element.field-element--large .field-input select,
	.field-element.field-element--regular .field-input select {
		background-position: top -262px right -104px;
		font-size: 1.4rem;
		padding: 7px 15px 7px;
		padding-right: 55px;
	}
}


/* ---- Checkboxes and radio buttons ---- */
.field-element.field-element--checkbox,
.field-element.field-element--radio {
	font-size: 1.6rem;
}

.field-element input[type="checkbox"],
.field-element input[type="radio"] {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.field-element input[type="checkbox"] + label,
.field-element input[type="radio"] + label {
	padding-left: 34px;
	position: relative;
	cursor: pointer;
	display: inline-block;
}

.field-element input[type="checkbox"] + label:before,
.field-element input[type="radio"] + label:before {
	content: " ";
	display: inline-block;
	width: 22px;
	height: 22px;
	margin-right: 13px;
	position: relative;
	top: 4px;
	background-color: #f1eeee;
	border: 1px solid #e6e1e1;
	margin-left: -34px;
	border-radius: 1px;
}

/* Check and dot */
.field-element input[type="checkbox"]:checked + label:after,
.field-element input[type="radio"]:checked + label:after {
	content: " ";
	position: absolute;
	left: 1px;
	top: 6px;
	font-size: 15px;
}


/* Without labels */
.field-element.field-element--checkbox--no-label input[type="checkbox"] + label,
.field-element.field-element--radio--no-label input[type="radio"] + label {
	padding-left: 0;
}

.field-element.field-element--checkbox--no-label input[type="checkbox"] + label:before,
.field-element.field-element--radio--no-label input[type="radio"] + label:before {
	margin-left: 0;
	margin-right: 0;
}

.field-element.field-element--checkbox--no-label input[type="checkbox"]:checked + label:after,
.field-element.field-element--radio--no-label input[type="radio"]:checked + label:after {
	left: 1px;
}

.field-element.field-element.field-element--radio--no-label input[type="radio"]:checked + label:after {
	margin-left: 4px;
}


/* -- Checkboxes -- */

/* Check (pseudo element) */
.field-element input[type="checkbox"]:checked + label:after {
	content: " ";
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -705px left 1px;
	width: 21px;
	height: 21px;
	margin: -1px 0 0 0px;
}

.field-element--checkboxlist--columns .field-element__input-set {
	-webkit-columns: 3;
	-moz-columns: 3;
	columns: 3;
}

/* -- Radio Buttons -- */

/* Radio button (pseudo element) */
.field-element input[type="radio"] + label:before {
	border-radius: 50%;
}

/* Radio dot (pseudo element) */
.field-element input[type="radio"]:checked + label:after {
	left: 7px;
	top: 11px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #242d36;
}

/* -- Varations -- */

/* White */
.field-element.field-element--white input[type="checkbox"] + label:before,
.field-element.field-element--white input[type="radio"] + label:before {
	background-color: #FFF;
}

/* Large */
.field-element.field-element--large input[type="checkbox"] + label:before,
.field-element.field-element--large input[type="radio"] + label:before {
	width: 25px;
	height: 25px;
	margin-right: 16px;
	top: 5px;
}

.field-element.field-element--large input[type="radio"]:checked + label:after {
	left: 8px;
	top: 12px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
}
.field-element.field-element--large input[type="checkbox"]:checked + label:after {
	background-position: top -758px left 1px;
	width: 24px;
	height: 25px;
	margin: -1px 0 0 -1px;
}

/* ---- Color picker ---- */
.field-element--colorpicker .textbox.colorpicker {
	padding: 3px 8px;
	height: 43px;
	width: 50px;
}

/* Small */
.field-element--colorpicker.field-element--small .textbox.colorpicker {
	padding: 2px 7px;
	height: 33px;
	width: 39px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 940px) {
	.field-element.field-element--colorpicker .textbox.colorpicker,
	.field-element.field-element--colorpicker.field-element--large .textbox.colorpicker,
	.field-element.field-element--colorpicker.field-element-regular .textbox.colorpicker {
		padding: 2px 7px;
		height: 33px;
		width: 39px;
	}
}

/* ---- Money ---- */
.field-element--money .field-input .textbox {
	padding-left: 41px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: top -447px left 0;
}

.field-element--money .field-input {
	position: relative;
}
.field-element--money .field-input .money-symbol:before {
	content: "\00a4";		/* Generic currency sign */
	position: absolute;
	display: block;
	top: 10px;
	left: 0;
	color: #9ba7b2;
	font-weight: bold;
	font-size: 19px;
	width: 41px;
	text-align: center;
}
.field-element--money .field-input .money-symbol--dollar:before {
	content: "$";    /* Dollars */
}
.field-element--money .field-input .money-symbol--pound:before {
	content: "\00a3";    /* Pounds */
}
.field-element--money .field-input .money-symbol--yen:before {
	content: "\00a5";    /* Yen */
}
.field-element--money .field-input .money-symbol--indian_rupee:before {
	content: "\20B9";   /* Indian rupee */
}


/* Small */
.field-element--money.field-element--small .field-input .textbox {
	background-position: top -447px left 0;
}
.field-element--money.field-element--small .field-input .money-symbol:before {
	top: 3px;
}

/* Large */
.field-element--money.field-element--large .field-input .textbox {
	background-position: top -447px left 0;
}
.field-element--money.field-element--large .field-input .money-symbol:before {
	top: 15px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 940px) {
	.field-element.field-element--money .field-input .textbox,
	.field-element.field-element--money.field-element--large .field-input .textbox,
	.field-element.field-element--money.field-element--regular .field-input .textbox {
		background-position: top -447px left 0;
		padding-left: 41px;
	}
	.field-element--money .field-input .money-symbol:before,
	.field-element--money.field-element--large .field-input .money-symbol:before,
	.field-element--money.field-element--regular .field-input .money-symbol:before {
		top: 5px;
	}
}


/* ---- Time pickers ---- */

.field-element--timepicker .field-input .textbox {
	padding-left: 41px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -163px left 12px;
}

.ui-timepicker {
	display: block;
	position: absolute;
	height: 200px;
	overflow: auto;
	z-index: 9999 !important;
}

.ui-timepicker-standard {
	background-color: #FFF;
	border: 1px solid #CED2DC;
	display: block;
	margin: 3px 0 0;
	padding: 5px;
	list-style: none outside none;
	border-radius: 4px;
}

.ui-timepicker-standard a {
	color: #24292f;
}

.ui-timepicker-standard .ui-state-hover {
	background-color: #EAECF1;
}

.ui-timepicker-standard .ui-menu-item {
	clear: left;
	float: left;
	margin: 0;
	padding: 0;
	width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
	display: block;
	padding: 5px 10px;
	line-height: 1.5;
	text-decoration: none;
	border-radius: 3px;
}

.ui-timepicker-hidden {
	display: none;
}

/* Small */
.field-element--timepicker.field-element--small .field-input .textbox {
	background-position: top -174px left 12px;
}

/* Large */
.field-element--timepicker.field-element--large .field-input .textbox {
	background-position: top -161px left 12px;
}


/* Large and regular field elements turn small */
@media screen and (max-width: 940px) {
	.field-element.field-element--timepicker .field-input .textbox,
	.field-element.field-element--timepicker.field-element--large .field-input .textbox,
	.field-element.field-element--timepicker.field-element--regular .field-input .textbox {
		background-position: top -175px left 12px;
		padding-left: 41px;
	}
}

/* ---- Date pickers ---- */

/* Date picker and Date-range picker*/
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox {
	padding-left: 41px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top 19px left 13px;
}

/* Small */
.field-element--datepicker.field-element--small .field-input .textbox,
.field-element--daterangepicker.field-element--small .field-input .textbox {
	background-position: top 7px left 12px;
}

/* Large */
.field-element--datepicker.field-element--large .field-input .textbox,
.field-element--daterangepicker.field-element--large .field-input .textbox {
	background-position: top 22px left 13px;
}


/* Large and regular field elements turn small */
@media screen and (max-width: 940px) {
	.field-element.field-element--datepicker .field-input .textbox,
	.field-element.field-element--datepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--datepicker.field-element--large .field-input .textbox,
	.field-element.field-element--daterangepicker .field-input .textbox,
	.field-element.field-element--daterangepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--daterangepicker.field-element--large .field-input .textbox {
		background-position: top 6px left 12px;
		padding-left: 41px;
	}
}


/* Date/time picker & date/time range picker */
.field-element--datetimepicker .field-input .textbox,
.field-element--datetimerangepicker .field-input .textbox {
	padding-left: 41px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -82px left 12px;
}

/* Small */
.field-element--datetimepicker.field-element--small .field-input .textbox,
.field-element--datetimerangepicker.field-element--small .field-input .textbox {
	background-position: top -93px left 12px;
}

/* Large */
.field-element--datetimepicker.field-element--large .field-input .textbox,
.field-element--datetimerangepicker.field-element--large .field-input .textbox {
	background-position: top -79px left 12px;
}

/* Large and regular field elements turn small */
@media screen and (max-width: 940px) {
	.field-element.field-element--datetimepicker .field-input .textbox,
	.field-element.field-element--datetimepicker.field-element--large .field-input .textbox,
	.field-element.field-element--datetimepicker.field-element--regular .field-input .textbox,
	.field-element.field-element--datetimerangepicker .field-input .textbox,
	.field-element.field-element--datetimerangepicker.field-element--large .field-input .textbox,
	.field-element.field-element--datetimerangepicker.field-element--regular .field-input .textbox {
		background-position: top -93px left 12px;
		padding-left: 41px;
	}
}


/* Container Appearance */
.daterangepicker {
	position: absolute;
	background: #fff;
	top: 100px;
	left: 20px;
	padding: 4px;
	margin-top: 2px;
	border-radius: 4px;
	width: 278px;
}

.daterangepicker.opensleft:before {
	position: absolute;
	top: -7px;
	right: 9px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.opensleft:after {
	position: absolute;
	top: -6px;
	right: 10px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.openscenter:before {
	position: absolute;
	top: -7px;
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.openscenter:after {
	position: absolute;
	top: -6px;
	left: 0;
	right: 0;
	width: 0;
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.opensright:before {
	position: absolute;
	top: -7px;
	left: 9px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.daterangepicker.opensright:after {
	position: absolute;
	top: -6px;
	left: 10px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	border-left: 6px solid transparent;
	content: '';
}

.daterangepicker.dropup{
	margin-top: -5px;
}

.daterangepicker.dropup:before{
	top: initial;
	bottom:-7px;
	border-bottom: initial;
	border-top: 7px solid #CED2DC;
}

.daterangepicker.dropup:after{
	top: initial;
	bottom:-6px;
	border-bottom: initial;
	border-top: 6px solid #fff;
}

.daterangepicker.dropdown-menu {
	max-width: none;
	z-index: 3000;
	display: none;
	border: 1px solid #CED2DC;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
	float: none;
}

.daterangepicker .calendar {
	display: none;
	max-width: 270px;
	margin: 4px;
}

.daterangepicker.show-calendar .calendar {
	display: block;
}

.daterangepicker .calendar.single .calendar-table {
	border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
	white-space: nowrap;
	text-align: center;
	min-width: 32px;
	border: 1px solid #E8E8E8;
}

.daterangepicker .calendar-table {
	border: 1px solid #ddd;
	padding: 4px;
	border-radius: 4px;
	background: #fff;
}

.daterangepicker table {
	width: 100%;
	margin: 0;
	border: none;
}

.daterangepicker td,
.daterangepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	white-space: nowrap;
	padding: 2px;
}
.daterangepicker td,
.daterangepicker th.available {
	cursor: pointer;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
	color: #999;
	background: #fff;
	border-color: #E8E8E8;
}
.daterangepicker td.off.in-range {
	background-color: #F4FAFC;
}
.daterangepicker td.off.active {
	background-color: #AAD8EA;
	color: #FFF;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
	color: #999;
	cursor: not-allowed;
	text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
	background: #EAECF1;
}

.daterangepicker td.in-range {
	background: #ebf4f8;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
	background-color: #358AAB;
	border-color: #358AAB;
	color: #fff;
}

.daterangepicker td.week, .daterangepicker th.week {
	font-size: 80%;
	color: #ccc;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
	font-size: 12px;
	padding: 1px;
	height: auto;
	margin: 0;
	cursor: default;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
	margin-bottom: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	width: 62px;
	background-color: #FFF;
	outline: none;
	border-radius: 5px;
	font-size: 1.4rem;
	line-height: 1.2;
	padding: 5px 23px 5px 8px;
	border: 1px solid #CED2DC;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: top -355px right -119px;
}

.daterangepicker select.monthselect {
	margin-right: 2%;
	width: 40%;
}

.daterangepicker select.yearselect {
	width: 44%;
}

.daterangepicker th.month {
	width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
	width: 11px;
	height: 17px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	display: block;
	margin: 0 auto;
}
.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
	background: none;
	opacity: 0.8;
}
.daterangepicker th.prev .daterangepicker-prev-icon {
	background-position: top -820px left 0px;
}
.daterangepicker th.next .daterangepicker-next-icon {
	background-position: top -880px left 1px;
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
	border: none;
	background: none;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
	background-color: #F2F2F5;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
	border: 1px solid #ccc;
	border-radius: 4px;
	color: #555;
	display: block;
	height: 30px;
	line-height: 30px;
	vertical-align: middle;
	margin: 0 0 5px 0;
	padding: 0 6px 0 28px;
	width: 100%;
}

.daterangepicker .input-mini.active {
	border: 1px solid #358AAB;
	background-color: rgb(255, 255, 238);
}

.daterangepicker .daterangepicker_input i {
	position: absolute;
	left: 8px;
	top: 7px;
	color: #596474;
}

.daterangepicker .daterangepicker_input {
	position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
	text-align: center;
	margin: 5px auto;
	line-height: 30px;
	position: relative;
}

.daterangepicker .calendar-time select.disabled {
	color: #ccc;
	cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
	font-size: 11px;
	float: none;
	margin: 4px;
	text-align: left;
}

.daterangepicker .applyBtn {
	background-color: #35ab75;
	color: #FFF;
}
.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
	background-color: #2C9867;
	color: #FFF;
}

.daterangepicker .cancelBtn {
	background-color: #D0D3DA;
	color: #24292F;
}
.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
	background-color: #BCBFC5;
	color: #24292f;
}

.daterangepicker .ranges ul {
	list-style: none;
	margin: 0 auto 12px;
	padding: 0;
	width: 100%;
}

.daterangepicker .ranges li {
	font-size: 1.3rem;
	background: #EAECF1;
	border: 1px solid #f5f5f5;
	color: #24292F;
	padding: 4px 12px 3px;
	margin-bottom: 7px;
	border-radius: 5px;
	cursor: pointer;
	line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
	background: #358AAB;
	border: 1px solid #358AAB;
	color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 600px) {
	.daterangepicker{
		width: auto;
	}

	.daterangepicker .ranges ul {
		width: 160px;
	}

	.daterangepicker.single .ranges ul {
		width: 100%;
	}

	.daterangepicker .calendar.left .calendar-table {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.daterangepicker .calendar.right .calendar-table {
		border-left: none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.daterangepicker .calendar.left {
		clear: left;
		margin-right: 0;
	}

	.daterangepicker.single .calendar.left {
		clear: none;
	}

	.daterangepicker.single .ranges,
	.daterangepicker.single .calendar{
		float:left;
	}

	.daterangepicker .calendar.right {
		margin-left: 0;
	}

	.daterangepicker .left .daterangepicker_input {
		padding-right: 12px;
	}

	.daterangepicker .calendar.left .calendar-table {
		padding-right: 12px;
	}

	.daterangepicker .ranges,
	.daterangepicker .calendar {
		float: left;
	}

}

@media (min-width: 769px) {
	.daterangepicker .ranges {
		width: auto;
		float: left;
	}

	.daterangepicker .calendar.left {
		clear: none;
	}
}


/* ---- File upload area ---- */
.file-upload__input {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.file-upload__helptext {
	padding-top: 14px;
}
.file-upload__helptext--hidden {
	display: none;
}
.file-upload__helptext__line2 {
	display: block;
	font-size: 1.3rem;
}
.file-upload__helptext p {
	margin: 0;
}
.file-upload__helptext:before {
	content: " ";
	display: block;
	width: 27px;
	height: 32px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0 -1317px;
	margin: 0 auto 10px;
}

.file-upload__area {
	cursor: pointer;
	text-align: center;
}
.field-input .textbox.file-upload__area--dragenter {
	border-color: #35ab75;
}

.file-upload__uploads:before,
.file-upload__uploads:after {
	content: " ";
	display: table;
}

.file-upload__uploads:after {
	clear: both;
}
.file-upload__item,
.file-upload__helptext {
	height: 110px;
	margin-top: 8px;
	margin-bottom: 8px;
}
.file-upload__item {
	background-color: #FFF;
	border: 1px solid #ced2dc;
	width: 110px;
	display: inline-block;
	margin-left: 6px;
	margin-right: 6px;
	position: relative;
	cursor: default;
	vertical-align: top;
}

.file-upload__item__remove {
	-webkit-appearance: none;
	padding: 0;
	outline: none;
	border: none;
	background: none;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	background: rgba(0, 0, 0, 0.3);
}
.file-upload__item__remove:hover,
.file-upload__item__remove:focus {
	background: rgba(0, 0, 0, 0.7);
}

.file-upload__item__remove:after {
	content: " ";
	display: block;
	width: 17px;
	height: 17px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 3px -938px;
}
.file-upload__item__remove__text {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* Feedback */
.file-upload__item__feedback__response {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.file-upload__item__feedback__response p {
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	overflow: hidden;
	margin: 0;
}

/* Not image response */
.file-upload__item__feedback__response .file-upload__item__feedback__name {
	font-size: 1.2rem;
	margin: 0 0 5px;
}
.file-upload__item__feedback__size {
	font-size: 1.1rem;
	margin: 0;
}
.file-upload__item__feedback__response--success--not-image {
	padding: 26px 15px 20px;
}
.file-upload__item__feedback__response--success--not-image:before {
	content: " ";
	display: block;
	width: 28px;
	height: 24px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0px -1396px;
	margin: 0 auto 10px;
}

/* Image response */
.file-upload__item__feedback__image {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
}

/* Error */
.file-upload__item__feedback__response--error {
	padding: 25px 15px 20px;
}
.file-upload__item__feedback__response .file-upload__item__feedback__error__text {
	font-size: 1.2rem;
	white-space: normal;
	overflow: visible;
}
.file-upload__item__feedback__response--error:before {
	content: " ";
	display: block;
	width: 23px;
	height: 24px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: 0px -1473px;
	margin: 0 auto 10px;
}

/* Hover */
.file-upload__item__feedback__hover {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0,0,0,0.5);
	color: #FFF;
	padding: 33px 15px 20px;
	overflow: hidden;
}
.file-upload__item:hover .file-upload__item__feedback__hover {
	opacity: 1;
}
.file-upload__item__feedback__hover__size {
	font-size: 1.1rem;
}
.file-upload__item__feedback__hover__dimensions {
	font-size: 1.1rem;
}
.file-upload__item__feedback__hover .file-upload__item__feedback__hover__name {
	font-size: 1.1rem;
	margin-bottom: 6px;
}

/* Progress circles */
.file-upload__progress-circle {
	position: relative;
	font-size: 1.3rem;
	line-height: 1.1;
	display: block;
	width: 56px;
	height: 56px;
	padding: 21px 0 0 0;
	margin: 26px auto 0;
	text-align: center;
}
.file-upload__progress-circle__amount {
	z-index: 1;
	position: relative;
}
.file-upload__progress-circle__pie {
	-webkit-transform: rotate(-90deg);
	    -ms-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
}
.file-upload__progress-circle__pie__piece {
	-webkit-transition: all 100ms ease-in-out;
	-o-transition: all 100ms ease-in-out;
	transition: all 100ms ease-in-out;
}



/* ---- File selector ---- */
.field-element--fileselector .fs-select-button {
	float: left;
	margin-right: 9px;
}

.field-element--fileselector .fs-preview-wrapper {
	background-color: #EEF0F3;
	position: relative;
	overflow: hidden;
	display: block;
}

.field-element--fileselector .fs-filename {
	padding: 10px 15px;
	word-break: break-all;
	overflow: hidden;
	display: block;
}
.field-element--fileselector .fs-file-selected .fs-filename {
	padding-left: 10px;
	padding-right: 38px;
}

.field-element--fileselector .fs-preview {
	width: 39px;
	display: none;
	float: left;
}
.field-element--fileselector .fs-file-selected .fs-preview {
	display: block;
}

.field-element--fileselector .fs-remove {
	display: none;
	-webkit-appearance: none;
	outline: none;
	border: none;
	background: none;
	padding: 0;
	color: #D4D7DE;
	position: absolute;
	right: 6px;
	top: 9px;
	background-image: url(../images/form-icons.svg);
	background-repeat: no-repeat;
	background-position: top -1075px left 3px;
	width: 22px;
	height: 22px;
}
.field-element--fileselector .fs-remove:hover,
.field-element--fileselector .fs-remove:focus {background-position: top -1135px left 3px;}
.field-element--fileselector .fs-file-selected .fs-remove {
	display: block;
}


/* Small */
.field-element--fileselector.field-element--small .fs-preview {
	width: 30px;
}
.field-element--fileselector.field-element--small .fs-filename {
	padding-top: 8px;
	padding-bottom: 6px;
	font-size: 1.4rem;
}
.field-element--fileselector.field-element--small .fs-remove {
	top: 6px;
	background-position: top -964px left 3px;
	width: 20px;
	height: 20px;
}
.field-element--fileselector.field-element--small .fs-remove:hover,
.field-element--fileselector.field-element--small .fs-remove:focus {
	background-position: top -1023px left 3px;
}
.field-element--fileselector.field-element--small .fs-file-selected .fs-filename {
	padding-right: 31px;
}

/* Large */
.field-element--fileselector.field-element--large .fs-preview {
	width: 48px;
}
.field-element--fileselector.field-element--large .fs-filename {
	padding-top: 13px;
	padding-bottom: 13px;
	padding-left: 25px;
	padding-right: 25px;
	font-size: 1.8rem;
}
.field-element--fileselector.field-element--large .fs-remove {
	top: 14px;
	right: 9px;
}
.field-element--fileselector.field-element--large .fs-file-selected .fs-filename {
	padding-right: 41px;
	padding-left: 20px;
}

/* Popup */
.file-selector-search-wrapper  {
	display: none;
}
.file-selector-search-wrapper.searched  {
	display: block;
}
#file-selector-preview {
	display: block;
	background: #f9f9fb;
	border: 1px solid #E0E3EA;
	margin: 0 0 20px;
	position: relative;
	padding: 20px 25px;
}
.no-results #file-selector-preview {
	display: none;
}
#file-selector-preview .preview-title {
	margin: 0 0 12px;
}
#file-selector-preview .preview-box {
	position: relative;
	height: 150px;
}
#file-selector-preview img {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	    transform: translate(-50%, -50%);
	margin: 0 auto;
	display: block;
}

#file-selector-upload .field-element--new-category,
#file-selector-upload.new-category .field-element--select-category {
	display: none;
}
#file-selector-upload.new-category .field-element--new-category,
#file-selector-upload .field-element--select-category {
	display: block;
}

#file-selector-result-wrap {
	max-height: 450px;
	overflow: auto;
	border-top: 1px solid #D7D7D7;
	border-bottom: 1px solid #D7D7D7;
}
#file-selector-upload .field-elements-inline,
#file-selector-search .field-elements-inline {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}
#file-selector-upload .field-elements-inline .field-element--dropdown,
#file-selector-search .field-elements-inline .field-element--dropdown {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
}
#file-selector-upload .field-elements-inline .field-element--text,
#file-selector-search .field-elements-inline .field-element--text {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	    -ms-flex-positive: 1;
	        flex-grow: 1;
}

#file-selector-stats {
	font-size: 1.6rem;
	margin-bottom: 14px;
}
#file-selector-results .file-select-table {
	margin: 0;
	width: 100%;
	border-left: 1px solid #E0E3EA;
	border-right: 1px solid #E0E3EA;
}
#file-selector-results .file-select-table tr {
	border-bottom: 1px solid #D7D7D7;
	cursor: pointer;
}
#file-selector-results .file-select-table tr:last-child {
	border-bottom: 0;
}
#file-selector-results .file-select-table tr:nth-child(odd) {
	background-color: #f9f9fb;
}

/* Hover */
#file-selector-results .file-select-table tr:hover {
	background-color: #ECE9E9;
}

#file-selector-paginate {
	margin-top: 16px;
}
#file-selector-prev {
	float: left;
}
#file-selector-next {
	float: right;
}
#file-selector-results a {
	text-decoration: none;
	color: inherit;
	display: block;
}
#file-selector-results .file-select-table-thumbnail {
	width: 100px;
	padding: 5px 20px;
}
#file-selector-results .file-select-table-text a {
	padding: 27px 25px 27px 0;
}
#file-selector-results .table--content-standard tr:hover td {
	background-color: red;
}

/* ---- Autocomplete ---- */
.ui-autocomplete {
	list-style: none;
	background: #FFFFFF;
	max-height: 400px;
	overflow-y: auto;
	border: 1px solid #CED2DC;
	border-radius: 4px;
	padding: 3px 0;
	margin: 0;
}
.ui-autocomplete .ui-menu-item {
	padding: 5px 11px;
	cursor: pointer;
}
.ui-autocomplete .ui-menu-item.ui-state-focus {
	padding: 5px 11px;
	background: #358AAB;
	color: #FFF;
}


/* ---- Lightweight elements ---- */
.field-element--lightweight {
	margin: 0;
}


/* ---- TinyMCE tweaks ---- */
.mce-panel {
	border-color: #CED2DC !important;
}
.mce-toolbar-grp,
.mce-flow-layout {
	background-color: #EAECF1 !important;
}
.mce-tinymce{
	border-radius: 5px !important;
	overflow: hidden !important;
}
.mce-btn {
	border: 1px solid #EAECF1 !important;
	background-color: #EAECF1 !important;
}
.mce-btn:hover,
.mce-btn:focus{
	color: #333;
	background-color: #DDE0E6 !important;
	border-color: #C6CAD2 !important;
}
.mce-btn.mce-active,
.mce-btn.mce-active:hover{
	background-color: #CED2DC !important;
	border-color: #B5B8C1 !important;
}
.mce-btn:active{
	background-color:#e0e0e0 !important;
	border-color: #ccc !important;
}
.mce-menu-item:hover, .mce-menu-item.mce-selected, .mce-menu-item:focus {
	background-color: #358AAB !important;
	color: #FFF !important;
}


/* ---- Responsiveness ---- */

/* Large and regular field elements turn small */
@media screen and (max-width: 940px) {
	.field-element .field-input .textbox,
	.field-element.field-element--large .field-input .textbox,
	.field-element.field-element--regular .field-input .textbox {
		font-size: 1.4rem;
		padding: 8px 12px 7px;
	}

}


/* ---- Submit Bar ---- */
.submit-bar {
	text-align: right;
	background: #f1eeee;
	padding: 14px;
}

.sidebar .submit-bar {
	background: none;
	padding: 0 0 10px;
}

.submit-bar a {
	margin-right: 20px;
}


/* ---- Search Form ---- */
input.search-query {
	margin-bottom: 0;
	padding-right: 14px;
	padding-left: 14px;
	border-radius: 0;

}

.form-search .input-append .search-query,
.form-search .input-prepend .search-query {
	border-radius: 0;
}

.form-search .input-append .search-query {
	border-radius: 14px 0 0 14px;
}

.form-search .input-append .btn {
	border-radius: 0 14px 14px 0;
}

.form-search .input-prepend .search-query {
	border-radius: 0 14px 14px 0;
}

.form-search .input-prepend .btn {
	border-radius: 14px 0 0 14px;
}

.form-search {
	/*radio*/
}
.form-search input,
.form-search textarea,
.form-search select {
	display: inline-block;
	margin-bottom: 0;
	vertical-align: middle;
}
.form-search .hide {
	display: none;
}
.form-search label,
.form-search .btn-group {
	display: inline-block;
}
.form-search .radio,
.form-search .checkbox {
	margin-bottom: 0;
	padding-left: 0;
	vertical-align: middle;
}
.form-search .radio input[type="radio"],
.form-search .checkbox input[type="checkbox"] {
	float: left;
}
.form-search .radio input[type="radio"] margin,
.form-search .checkbox input[type="checkbox"] margin {
	right: 3px;
	left: 0;
}
.form-search .control-group {
	margin-bottom: 0.75em;
}
.form-search legend + .control-group {
	margin-top: 1.5em;
	-webkit-margin-top-collapse: separate;
}


/* ---- Search pagination ---- */
.search-paginate {
	padding: 10px 0;
	text-align: center;
}
.search-paginate .page-prev {
	margin: 0 8px 0 0;
}
.search-paginate .page {
	padding: 2px 9px;
	margin: 0 3px;
	background: #eee;
	border: 1px #e85505 solid;
	text-decoration: none;
}
.search-paginate .page.on {
	color: #000;
	border-color: #000;
}
.search-paginate .page-next {
	margin: 0 0 0 8px;
}


/* ---- Captcha ---- */
.captcha {
	background-color: #f6f6f6;
	padding: 10px;
	width: 100%;
	border: 1px solid #D7D7D7;
}

.captcha .captcha-info, .captcha .info {
	float: right;
	padding-bottom: 8px;
}
.captcha .captcha-info, .captcha .info a {
	text-decoration: none;
}
.captcha .captcha-info .captcha-icon {
	width: 16px;
	height: 16px;
	margin-bottom: 16px;
}

.captcha .captcha-img {
	width: 200px;
	margin-bottom: 10px;
}

.captcha .captcha-input {
	width: 200px;
}


/* ------------------------------------------------------
**** Tables
------------------------------------------------------ */

table {
	border-collapse: collapse;
	border: 1px solid #e6e1e1;
}

th,
td {
	padding: 5px 10px;
	border: 1px solid #e6e1e1;
}

th,
thead td {
	background: #f1eeee;
}

/* ---- Content tables ---- */
.table--content-standard {
	width: 100%;
	margin: 2rem 0;
}
.table--content-standard caption {
	text-align: left;
	padding-bottom: 13px;
}
.table--content-standard th,
.table--content-standard thead td {
	font-weight: bold;
	background-color: #f1eeee;
	font-size: 1.7rem;
}
.table--content-standard td {
	background-color: #FFF;
}
.table--content-standard th,
.table--content-standard td {
	border: 1px solid #e6e1e1;
	padding: 12px 15px;
	text-align: left;
}


/* Small */
.table--content-standard.table--content-small {
	font-size: 1.3rem;
}
.table--content-standard.table--content-small th,
.table--content-standard.table--content-small td {
	padding: 5px 10px;
}
.table--content-standard.table--content-small th,
.table--content-standard.table--content-small thead td {
	font-size: 1.4rem;
}

/* ---- Responsive table ---- */
table.responsive {
	overflow: auto;
	overflow-y: hidden;
	overflow-x: auto;
	display: block;
}

/* ---- No Styles Table ---- */
.table__no-styles {
	border-collapse: separate !important;
	border: none !important;
	background: none !important;
	min-width: 0 !important;
}

.table__no-styles th,
.table__no-styles thead td,
.table__no-styles td {
	padding: 0 !important;
	border: 0 !important;
	background: none !important;
}




/* ------------------------------------------------------
**** Helper classes
------------------------------------------------------ */

/* ---- Clearfix ---- */
.clear {
	height: 0;
	clear: both;
	display: block;
}

.-clearfix:before,
.-clearfix:after {
	content: " ";
	display: table;
}

.flexbox .-clearfix:before,
.flexbox .-clearfix:after {
    width: 0;
}

.-clearfix:after {
	clear: both;
}


/* ---- Hiding classes ---- */
.-ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
}

.-ir:before {
	content: "";
	display: block;
	width: 0;
	height: 150%;
}

.-hidden {
	display: none !important;
	visibility: hidden;
}

.-vis-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.-vis-hidden.focusable:active,
.-vis-hidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

.-invisible {
	visibility: hidden;
}

/* ---- Hide/show ---- */

/* BP 1 */
@media screen and (max-width: 599px) {
	.show--bp1 {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
}

/* BP 2 */
@media screen and (max-width: 768px) {
	.show--bp2 {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
}

/* BP 3 */
@media screen and (max-width: 939px) {
	.show--bp3 {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
}

/* BP 4 */
@media screen and (max-width: 1229px) {
	.show--bp4 {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
}

/* ---- Offset ---- */

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.offset {
		padding-left: 25px;
	}
}

/* ---- Pseudo elements ---- */

/* Angle Brackets */
.-r-arrow-after:after {
	content: "\00a0\003e";
}
.-r-arrow-before:before {
	content: "\003e\00a0";
}
.-l-arrow-after:after {
	content: "\00a0\003c";
}
.-l-arrow-before:before {
	content: "\003c\00a0";
}

/* Ellipsis */
.ellipsis-2:after {
	content: "..";
}
.ellipsis-3:after {
	content: "...";
}

/* ---- Floats and positioning ---- */
.left,
.center,
.right {
	margin: 0 auto 20px;
	display: block;
}

.block {
	margin: 0 0 20px;
	display: block;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.left,
	.center,
	.right {
		margin: 0 auto 20px;
		display: block;
	}
	.left {
		margin: 0 30px 30px 0;
		float: left;
	}

	.right {
		margin: 0 0 30px 30px;
		float: right;
	}
}

/* ---- Grid system ---- */
.grid:before,
.grid:after {
	content: " ";
	display: table;
}

.grid:after {
	clear: both;
}

.grid-col {
	float: left;
	margin-right: 1.754385964912%;
}
.grid-col:last-child,
.grid-col.grid-col-last-in-row {
	margin-right: 0;
}

/* Reverse */
.grid.grid-reverse .grid-col {
	float: right;
	margin-right: 0;
	margin-left: 1.754385964912%;
}
.grid.grid-reverse .grid-col:last-child,
.grid.grid-reverse .grid-col.grid-col-last-in-row {
	margin-left: 0;
}

/* 2 col grid */
.grid-2-cols .grid-col:nth-child(2n+2) {
	margin-right: 0;
}
/* 3 col grid */
.grid-3-cols .grid-col:nth-child(3n+3) {
	margin-right: 0;
}
/* 4 col grid */
.grid-4-cols .grid-col:nth-child(4n+4) {
	margin-right: 0;
}

/* 1 col */
.grid-col-1 {
	width: 6.725146198830%;
}
/* 2 col */
.grid-col-2 {
	width: 15.204678362573%;
}
/* 3 col */
.grid-col-3 {
	width: 23.684210526316%;
}
/* 4 col */
.grid-col-4 {
	width: 32.163742690058%;
}
/* 5 col */
.grid-col-5 {
	width: 40.643274853801%;
}
/* 6 col */
.grid-col-6 {
	width: 49.122807017544%;
}
/* 7 col */
.grid-col-7 {
	width: 57.602339181287%;
}
/* 8 col */
.grid-col-8 {
	width: 66.081871345029%;
}
/* 9 col */
.grid-col-9 {
	width: 74.561403508772%;
}
/* 10 col */
.grid-col-10 {
	width: 83.040935672515%;
}
/* 11 col */
.grid-col-11 {
	width: 91.520467836257%;
}
/* 12 col */
.grid-col-12 {
	width: 100%;
	margin-right: 0;
}

/* BP 4 */
@media screen and (max-width: 1229px) {

	/* 1 col */
	.grid-col-1-bp1 {
		width: 6.725146198830%;
	}
	/* 2 col */
	.grid-col-2-bp1 {
		width: 15.204678362573%;
	}
	/* 3 col */
	.grid-col-3-bp1 {
		width: 23.684210526316%;
	}
	/* 4 col */
	.grid-col-4-bp1 {
		width: 32.163742690058%;
	}
	/* 5 col */
	.grid-col-5-bp1 {
		width: 40.643274853801%;
	}
	/* 6 col */
	.grid-col-6-bp1 {
		width: 49.122807017544%;
	}
	/* 7 col */
	.grid-col-7-bp1 {
		width: 57.602339181287%;
	}
	/* 8 col */
	.grid-col-8-bp1 {
		width: 66.081871345029%;
	}
	/* 9 col */
	.grid-col-9-bp1 {
		width: 74.561403508772%;
	}
	/* 10 col */
	.grid-col-10-bp1 {
		width: 83.040935672515%;
	}
	/* 11 col */
	.grid-col-11-bp1 {
		width: 91.520467836257%;
	}
	/* 12 col */
	.grid-col-12-bp1 {
		width: 100%;
		margin-right: 0;
	}
}

/* BP 3 */
@media screen and (max-width: 939px) {

	/* 1 col */
	.grid-col-1-bp2 {
		width: 6.725146198830%;
	}
	/* 2 col */
	.grid-col-2-bp2 {
		width: 15.204678362573%;
	}
	/* 3 col */
	.grid-col-3-bp2 {
		width: 23.684210526316%;
	}
	/* 4 col */
	.grid-col-4-bp2 {
		width: 32.163742690058%;
	}
	/* 5 col */
	.grid-col-5-bp2 {
		width: 40.643274853801%;
	}
	/* 6 col */
	.grid-col-6-bp2 {
		width: 49.122807017544%;
	}
	/* 7 col */
	.grid-col-7-bp2 {
		width: 57.602339181287%;
	}
	/* 8 col */
	.grid-col-8-bp2 {
		width: 66.081871345029%;
	}
	/* 9 col */
	.grid-col-9-bp2 {
		width: 74.561403508772%;
	}
	/* 10 col */
	.grid-col-10-bp2 {
		width: 83.040935672515%;
	}
	/* 11 col */
	.grid-col-11-bp2 {
		width: 91.520467836257%;
	}
	/* 12 col */
	.grid-col-12-bp2 {
		width: 100%;
		margin-right: 0;
	}
}

/* BP 2 */
@media screen and (max-width: 768px) {

	/* 1 col */
	.grid-col-1-bp3 {
		width: 6.725146198830%;
	}
	/* 2 col */
	.grid-col-2-bp3 {
		width: 15.204678362573%;
	}
	/* 3 col */
	.grid-col-3-bp3 {
		width: 23.684210526316%;
	}
	/* 4 col */
	.grid-col-4-bp3 {
		width: 32.163742690058%;
	}
	/* 5 col */
	.grid-col-5-bp3 {
		width: 40.643274853801%;
	}
	/* 6 col */
	.grid-col-6-bp3 {
		width: 49.122807017544%;
	}
	/* 7 col */
	.grid-col-7-bp3 {
		width: 57.602339181287%;
	}
	/* 8 col */
	.grid-col-8-bp3 {
		width: 66.081871345029%;
	}
	/* 9 col */
	.grid-col-9-bp3 {
		width: 74.561403508772%;
	}
	/* 10 col */
	.grid-col-10-bp3 {
		width: 83.040935672515%;
	}
	/* 11 col */
	.grid-col-11-bp3 {
		width: 91.520467836257%;
	}
	/* 12 col */
	.grid-col-12-bp3 {
		width: 100%;
		margin-right: 0;
	}
}

/* BP 1 */
@media screen and (max-width: 599px) {

	/* 1 col */
	.grid-col-1-bp4 {
		width: 6.725146198830%;
	}
	/* 2 col */
	.grid-col-2-bp4 {
		width: 15.204678362573%;
	}
	/* 3 col */
	.grid-col-3-bp4 {
		width: 23.684210526316%;
	}
	/* 4 col */
	.grid-col-4-bp4 {
		width: 32.163742690058%;
	}
	/* 5 col */
	.grid-col-5-bp4 {
		width: 40.643274853801%;
	}
	/* 6 col */
	.grid-col-6-bp4 {
		width: 49.122807017544%;
	}
	/* 7 col */
	.grid-col-7-bp4 {
		width: 57.602339181287%;
	}
	/* 8 col */
	.grid-col-8-bp4 {
		width: 66.081871345029%;
	}
	/* 9 col */
	.grid-col-9-bp4 {
		width: 74.561403508772%;
	}
	/* 10 col */
	.grid-col-10-bp4 {
		width: 83.040935672515%;
	}
	/* 11 col */
	.grid-col-11-bp4 {
		width: 91.520467836257%;
	}
	/* 12 col */
	.grid-col-12-bp4 {
		width: 100%;
		margin-right: 0;
	}
}

/* ------------------------------------------------------
**** Template Layout
------------------------------------------------------ */

* {
	margin: 0;
}

html,
body {
	background: #FFF;
	height: 100%;
}

#wrap {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	min-height: 100%;
}

.container {
	width: 88%;
	margin: 0 auto;
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.container {
		width: 670px;
	}
}


/* BP 3 */
@media screen and (min-width: $bp-940) {

	.container {
		width: auto;
		margin-left: 40px;
		margin-right: 40px;
	}
}
@media screen and (min-width: $bp-1380) {

	.container {

		margin-left: 100px;
		margin-right: 100px;
	}
}




/* ---- simple content alignment classes ---- */

/*
For use fix flexgrid or when you don't need anything else
The breakpoints align with flexgrid breakpoints

*/

/* - right - */

.col--right-align {
	text-align: right;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.col-xs2--right-align {
		text-align: right;
	}
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.col-sm--right-align {
		text-align: right;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	.col-md--right-align {
		text-align: right;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.col-lg--right-align {
		text-align: right;
	}
}

/* - left - */

.col--left-align {
	text-align: left;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.col-xs2--left-align {
		text-align: left;
	}
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.col-sm--left-align {
		text-align: left;
	}
}


/* BP 3 */
@media screen and (min-width: $bp-940) {
	.col-md--left-align {
		text-align: left;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.col-lg--left-align {
		text-align: left;
	}
}


/* ---- Sections ---- */
.section {
	padding-top: 19px;
	padding-bottom: 19px;
	position: relative;
}


/* BP 1 */
@media screen and (min-width: $bp-600) {

}

/* BP 2 */
@media screen and (min-width: $bp-769) {

}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	.section {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

/* Max BP 4 */
@media screen and (max-width: 1229px) {
	.section > .container > h1,
	.section > .container > .h1 {
		margin-top: 0;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.section {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.section-header-w-cta {

	@media screen and (min-width: $bp-600) {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
	}

	@media screen and (min-width: $bp-1230) {
		margin-bottom: 16px;
	}


	&__heading:after {
		@media screen and (min-width: $bp-1230) {
			margin-top: 16px;
		}
	}

	&__cta {

		.button {
			width: 100%;
		}

		@media screen and (min-width: $bp-600) {
			padding-top: 15px;
			.button {
				width: auto;
			}
		}

	}
}

/* ---- Blocks ---- */

/* like sections, but using margin instead of padding */

.block {
	margin-top: 20px;
	margin-bottom: 20px;
}

.block-mb {
	margin-bottom: 20px;
}

.block-large {
	margin-top: 20px;
	margin-bottom: 20px;
}

.block-mb-large {
	margin-bottom: 20px;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.block-large {
		margin-top: 25px;
		margin-bottom: 25px;
	}

	.block-mb-large {
		margin-bottom: 25px;
	}
}
/* BP 2 */
@media screen and (min-width: $bp-769) {

	.block-large {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.block-mb-large {
		margin-bottom: 30px;
	}
}

/* ---- Background colours ---- */

/* White */
.bg-white {
	background-color: #fff;
}

/* Navy blue */
.bg-navyblue {
	background-color: #232c35;
}
.bg-navyblue {
	color: #AEB5BD;
}


/* Light grey */
.bg-lightgrey {
	background-color: #f1eeee;
}

/* Grey */
.bg-grey {
	background-color: #c2bdbd;
}

/* Red */
.bg-red {
	background-color: $color-brand-red;
}
.bg-red,
.bg-red a {
	color: #fff;
}

.bg-red .h1:after {
	background: #000000;
}

/* Black */
.bg-black {
	background-color: #000;
}
.bg-black,
.bg-black a {
	color: #fff;
}

.bg-black-transparent {
	background-color: rgba(0,0,0,.75);
}


/* Text on dark backgrounds */

.reverse-text {
	color: #fff;
}

.reverse-text a {
	color: #fff;
	text-decoration: none;
}

.reverse-text a:hover,
.reverse-text a:active,
.reverse-text a:focus {
	color: #fff;
	text-decoration: underline;
}

.pale-reverse-text,
.pale-reverse-text a {
	color: rgba(255,255,255,0.7);
	text-decoration: none;
}


.pale-reverse-text a:hover,
.pale-reverse-text a:active,
.pale-reverse-text a:focus {
	color: #fff;
	text-decoration: underline;
}


/* ---- Content Box ---- */

.box {
	padding: 20px 15px;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.box {
		padding: 20px;
	}
}
/* BP 2 */
@media screen and (min-width: $bp-769) {
	.box {
		padding: 30px;
	}
}

.box > *:last-child {
	margin-bottom: 0;
}


/* ---- Subnav ---- */
.section--subnav {
	padding-top: 0;
	padding-bottom: 0;
	text-align: right;
}

/* ---- Header ---- */
.section--header {
	padding-top: 4px;
	padding-bottom: 4px;
	background-color: #232c35;
}
.header-logo {
	margin: 6px 0 0;
	float: left;
}
.header-logo img {
	width: 161px;
	height: 24px;
}
.header-logo a {
	display: block;
}
.header-contact {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}
.header-contact a {
	color: #fff;
	text-decoration: none;
	display: block;
}
.header-contact a:hover {
	color: $color-black;
}
.header-contact__call {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	margin: 0;
	text-transform: uppercase;
	font-family: $alternate-font;
	font-size: 1.1rem;
}
.header-contact__book {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	text-align: right;
	margin: 0;
	color: $color-brand-red;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 1.1rem;
	font-family: $alternate-font;
}

/* BP 1 */
@media screen and (min-width: 400px) {
	.header-contact__call,
	.header-contact__book {
		font-size: 1.2rem;
	}
	.section--header {
		padding-top: 4px;
		padding-bottom: 6px;
	}
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.header-contact__call,
	.header-contact__book {
		font-size: 1.4rem;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	.header-logo {
		margin: 9px 0 0;
		float: none;
	}
	.header-logo img {
		width: 300px;
		height: 44px;
	}
	.header-contact {
		display: block;
	}
	.header-contact a {
		color: inherit;
	}

	.header-contact__call {
		font-size: 2.6rem;
		font-family: "lato", Helvetica, Arial, sans-serif;
		font-weight: 700;
		-webkit-box-flex: 0;
		-webkit-flex: none;
		    -ms-flex: none;
		        flex: none;
		line-height: 1.3;
	}
	.header-contact__book {
		font-size: 1.2rem;
		-webkit-box-flex: 0;
		-webkit-flex: none;
		    -ms-flex: none;
		        flex: none;
		text-align: right;
	}
	.section--header {
		background-color: $color-brand-grey;
	}
	.header-contact {
		text-align: right;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.header-logo {
		margin: 11px 0 0;
	}
	.header-logo img {
		width: 390px;
		height: 57px;
	}
	.header-contact__call {
		font-size: 4rem;
	}
	.header-contact__book {
		font-size: 1.95rem;
	}
}

/* ---- Nav ---- */
.section--nav {
	padding-top: 0;
	padding-bottom: 0;
	background-color: $color-white;
	transition: $link-transition;
}
.sticky-nav .section--nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background-color: $color-brand-grey;
}


/* BP 3 */
@media screen and (min-width: $bp-940) {

	.section--nav {
		border-bottom: 1px solid #e6e1e1;
	}
	.section--header {
		padding-top: 9px;
		padding-bottom: 11px;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {

	.section--header {
		padding-top: 17px;
		padding-bottom: 17px;
	}
}


@import "02-layout/hero-banner";

@import "02-layout/locations";

/* ---- Info banner ---- */
.section--info-banner {
	padding-bottom: 0;
}
.info-banner__title {
	margin-bottom: 30px;
}
.info-banner__banner {
	position: relative;
}
.info-banner__banner__image {
	height: 180px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.info-banner__banner__content-wrap {
	background-color: #f1eeee;
	padding-bottom: 30px;
}
.info-banner__banner__content {
	z-index: 1;
	position: relative;
	text-align: left;
}
.info-banner__banner__content__text {
	padding: 8px 0 8px;
}

/* No title */
.section--info-banner--no-title {
	padding-top: 0;
}

/* TEMP */
.info-banner__banner__image {
	background-image: url(../images/temp/feature-tile.jpg);
}
.section--info-banner--no-title .info-banner__banner__image {
	background-image: url(../images/temp/feature-tile2.jpg);
}


/* BP 1 */
@media screen and (min-width: $bp-600) {
	.info-banner__banner__image {
		height: 250px;
	}
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.info-banner__banner__content-wrap {
		background-color: transparent;
		padding-bottom: 0;
	}
	.info-banner__banner__content {
		background: rgba(241,238,238,0.95);
	}
	.info-banner__banner__image {
		position: absolute;
		height: auto;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.info-banner__banner__content__text {
		padding: 50px 30px 20px;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	.info-banner__title {
		margin-left: 280px;
		margin-bottom: 50px;
	}
	.info-banner__banner__content__text {
		padding: 70px 40px 30px;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.info-banner__title {
		margin-left: 450px;
		margin-bottom: 60px;
	}
	.info-banner__banner__content__text {
		padding: 90px 40px 50px;
	}
}


/* ---- Inner page banner ---- */

.inner-page-banner {
	margin: 40px 0 -50px;
}



/*  Content to the left, button on the right ALA homepage */

@media screen and (min-width: 48em) {/*  768px */
    @supports (display: grid) {
    	.button-col {
    		display: grid;
    		grid-template-columns: 1fr auto;
    		grid-column-gap: 20px;
    		align-items: start;
    		margin-bottom: -20px;
    	}
    	.button-col .button {
    		margin-top: -5px;
    	}
    }
}



/* ---- Content ---- */


/* Mainbar */

/* Sidebar */
.sidebar-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}
.sidebar {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	 margin-top: 20px;
}
.sidebar .widget {
	margin-bottom: 5rem;
}
.sidebar .widget-title {
	font-size: 3.4rem;
	margin-bottom: 20px;
	position: relative;

	&:after {
		content: " ";
		width: 90px;
		height: 3px;
		background: $color-brand-red;
		display: block;
		margin-top: 8px;
		margin-left: -20px;
		@media screen and (min-width: $bp-600) {
			margin-top: 16px;
			width: 100px;
			margin-left: -30px;
		}
		@media screen and (min-width: $bp-769) {
			width: 110px;
			margin-left: -40px;
		}
	}
}



.sidebar__item {
	padding: 20px 20px;
	position: relative;
}
.sidebar__item--deal-promo + .sidebar__item--enquiry-form {
	padding-top: 0;
}
.sidebar__item--widgets {
	padding: 0;
}
.sidebar__item--widgets:first-child .widget:first-child {
	padding-top: 90px;
}
.sidebar__item > .widget-RichText:first-child {
	padding-top: 110px;
}
.sidebar__item .stacked-buttons {
	margin: 10px -20px 0;
}

.sidebar__item h1:first-child,
.sidebar__item .h1:first-child,
.sidebar__item h2:first-child,
.sidebar__item .h2:first-child {
	margin-top: 0;
}

@media screen and (min-width: $bp-1230) {
	.sidebar > .sidebar__item:first-child .widget-RelatedLinks {
		padding-top: 65px;
	}
}

/* Review */

.sidebar__item--review-widget {
    padding-top: 15px !important;
    margin-bottom: -15px;
}

/* Promo */
.sidebar__item.sidebar__item--promo {
	padding-right: 66px;
	padding-top: 30px;
	padding-bottom: 30px;
}
.sidebar__item--promo__content p:last-child {
	margin-bottom: 0;
}
.sidebar__item--promo__img {
	position: absolute;
	bottom: 0;
	right: -5px;
	width: 60px;
}

/* Enquiry */
.widget-EnquiryLink {
	padding-top: 65px;
}
.sidebar__item--enquiry-form {
	overflow: hidden;
}
.sidebar__item--enquiry-form__call.icon-before:before {
	font-size: 3.4rem;
	vertical-align: top;
	display: inline-block;
	margin-top: 2px;
}
.sidebar__item--enquiry-form__call {
	font-family: $display-font;
	font-weight: 300;
}
.sidebar__item--enquiry-form__call__number {
	text-decoration: none;
	color: inherit;
	font-size: 2.6rem;
}
.sidebar__item--enquiry-form__call__or {
	font-size: 1.8rem;
}

.sidebar__item--enquiry-form__call__email {
	font-size: 2rem;
	text-transform: uppercase;
}
.sidebar__item .g-recaptcha {
	-webkit-transform: scale(0.835);
	    -ms-transform: scale(0.835);
	        transform: scale(0.835);
	-webkit-transform-origin: top left;
	    -ms-transform-origin: top left;
	        transform-origin: top left;
}
.sidebar__item .g-recaptcha > div {
	width: 254px !important;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.sidebar__item {
		padding: 20px 30px;
	}
	.sidebar__item .stacked-buttons {
		margin: 0 -30px 0;
	}
	.sidebar__item .g-recaptcha {
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
	}
	.sidebar__item .g-recaptcha > div {
		width: auto !important;
	}
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.sidebar__item {
		padding: 35px 40px;
	}
	.sidebar__item .stacked-buttons {
		margin: 0 -40px 0;
	}
	.sidebar__item--promo__img {
		right: -6px;
		width: 80px;
	}
	.sidebar__item.sidebar__item--promo {
		padding-right: 96px;
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.sidebar__item--enquiry-form__call.icon-before:before {
		font-size: 3.8rem;
		margin-top: 2px;
	}
	.sidebar__item--enquiry-form__call__number {
		font-size: 3rem;
	}
	.sidebar__item--enquiry-form__call__or {
		font-size: 2rem;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.section--content {
		padding-top: 0;
		padding-bottom: 0;
	}
	.mainbar {
		padding: 71px 30px 80px 0;
	}
	.mainbar--w-sidebar {
		max-width: 82ch;
	}
	.section--products .mainbar {
		padding-top: 0;
		padding-bottom: 0;
	}
	.sidebar {
		margin-top: 0;
	}
	.sidebar__item {
		padding: 50px 40px 20px;
	}

	.sidebar__item--review-widget {
	    padding-bottom: 0px !important;
	}

	.sidebar__item .stacked-buttons {
		margin: 0 -40px 0;
	}
	.sidebar__item--promo__img {
		right: -12px;
		width: 160px;
	}
	.sidebar__item.sidebar__item--promo {
		padding-right: 166px;
	}
	.sidebar__item .g-recaptcha {
		-webkit-transform: scale(0.905);
		    -ms-transform: scale(0.905);
		        transform: scale(0.905);
	}
	.sidebar__item .g-recaptcha > div {
		width: 274px !important;
	}
}

/* ---- Side by side panel ---- */
.side-by-side {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	text-align: left;
}

.side-by-side--left > div > div {
	margin-bottom: auto;
}

.side-by-side--left > div > div > *:first-child {
	margin-top: 0;
}

.side-by-side > div {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
			flex: 1 1 auto;

	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
		-ms-flex-align: center;
			align-items: center;
}

.side-by-side > div:first-child {
	margin-bottom: 20px;
}

.side-by-side + .side-by-side {
	margin-top: 20px;
}

.side-by-side--img img {
	margin: 0 auto;
}

.side-by-side--img > div:first-child {
-webkit-box-ordinal-group: 2;
-webkit-order: 1;
	-ms-flex-order: 1;
		order: 1;

	margin-bottom: 0;
}

.side-by-side--img > div:last-child {
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
		-ms-flex-order: 0;
			order: 0;

	margin-bottom: 20px;
}

.side-by-side > div > * {
	width: 100%;
}

@media screen and (min-width: 780px) {
	.side-by-side {
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;

		margin-left: -1em;
    	margin-right: -1em;
	}

	.side-by-side.highlight,
	blockquote.side-by-side,
	.blockquote.side-by-side {
		margin-left: 0;
    	margin-right: 0;
	}

	.side-by-side > div {
		margin-left: 1em;
		margin-right: 1em;
		margin-bottom: 1em;

		-webkit-box-flex: 1;
		-webkit-flex: 1 1 50%;
			-ms-flex: 1 1 50%;
				flex: 1 1 50%;
	}

	.side-by-side--img > div:first-child {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
			order: 0;

		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
			-ms-flex: 1 1 auto;
				flex: 1 1 auto;
	}

	.side-by-side--img > div:last-child {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
			-ms-flex-order: 1;
				order: 1;

		-webkit-box-flex: 0;
		-webkit-flex: 0 0 33%;
			-ms-flex: 0 0 33%;
				flex: 0 0 33%;

		margin-bottom: 0;
	}
}

@media screen and (min-width: 1280px) {
	.side-by-side--img > div:last-child {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 50%;
			-ms-flex: 0 0 50%;
				flex: 0 0 50%;
	}
}

/* ---- Thumb gallery ---- */
.gallery-thumbs-wrapper {
	margin: 22px 0 10px;
}
.gallery-thumbs {
	list-style: none;
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
}
.gallery-thumbs__item {
	margin-bottom: 25px;
}
.gallery-thumbs__item__inner {
	border: 4px solid #f2efef;
	background: #232c35;
}
.gallery-thumbs__item__link {
	position: relative;
	display: block;
	text-decoration: none;
}
.gallery-thumbs__item__img {
	display: block;
}

.gallery-thumbs__item-hidden {
	display: none;
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	.gallery-thumbs__item__inner {
		border-width: 6px;
	}
	.gallery-thumbs-wrapper {
		margin: 35px 0 15px;
	}
}

/* Wider than iPad BP */
@media screen and (min-width: 1025px) {
	.gallery-thumbs__item__link:before {
		content: "\e909";
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%) scale(0.7);
            -ms-transform: translate(-50%, -50%) scale(0.7);
                transform: translate(-50%, -50%) scale(0.7);
        z-index: 4;
        color: #FFF;
        font-size: 3rem;
        opacity: 0;
        -webkit-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        transition: all 200ms ease;
	}

	.gallery-thumbs__item__link:hover .gallery-thumbs__item__img {
		opacity: 0.7;
	}
	.gallery-thumbs__item__link:hover:before {
		opacity: 1;
		-webkit-transform: translate(-50%, -50%) scale(1);
		    -ms-transform: translate(-50%, -50%) scale(1);
		        transform: translate(-50%, -50%) scale(1);
	}
}


/* ---- Image gallery widget ---- */

.widget-ImageGallery > div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row wrap;
	    -ms-flex-flow: row wrap;
	        flex-flow: row wrap;
	margin-right: -1.5rem;
	margin-left: -1.5rem;
}

.widget-ImageGallery .image-gallery-thumb {
	margin: 0;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	padding-bottom: 3rem;
	width: 50%;
}

.image-gallery-thumb .thumb {
	border: 4px solid #f2efef;
	display: block;
}

@media screen and (min-width: 30em) {/* 480px */
	.widget-ImageGallery .image-gallery-thumb {
		width: 33.3%;
	}
}

@media screen and (min-width: 48em) {/*  768px */

	.widget-ImageGallery {
		margin-bottom: -2rem;
	}
    .widget-ImageGallery > div {
    	margin-right: -2rem;
    	margin-left: -2rem;
    }

    .widget-ImageGallery .image-gallery-thumb {
    	margin: 0;
    	padding-right: 2rem;
    	padding-left: 2rem;
    	padding-bottom: 4rem;
    	width: 25%;
    }
}
@media screen and (min-width: 1025px) {
	.image-gallery-thumb .thumb {
		background: #232c35;
		position: relative;
	}
	.image-gallery-thumb .thumb:before {
		content: "\e909";
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translate(-50%, -50%) scale(0.7);
            -ms-transform: translate(-50%, -50%) scale(0.7);
                transform: translate(-50%, -50%) scale(0.7);
        z-index: 4;
        color: #FFF;
        font-size: 3rem;
        opacity: 0;
        -webkit-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        transition: all 200ms ease;
	}

	.image-gallery-thumb .thumb:hover img {
		opacity: 0.7;
	}
	.image-gallery-thumb .thumb:hover:before {
		opacity: 1;
		-webkit-transform: translate(-50%, -50%) scale(1);
		    -ms-transform: translate(-50%, -50%) scale(1);
		        transform: translate(-50%, -50%) scale(1);
	}
}


/* ---- Content slider ---- */
.section--content-slider {
	padding-top: 0;
	padding-bottom: 0;
	position: relative;
}

/* Slider */
.content-slider {
	list-style: none;
	padding: 0;
	margin: 0;
}
.content-slider .slick-slide {
	position: relative;
	outline: none;
}
.content-slider__item__img {
	width: 100%;
}
.content-slider .slick-arrow {
	-webkit-appearance: none;
	padding: 0;
	border: none;
	outline: none;
	background: none;
	position: absolute;
	top: 50%;
	margin-top: -80px;
	height: 160px;
	width: 40px;
	text-indent: -9999px;
	z-index: 9;
}
.content-slider .slick-arrow:before {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	-webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	color: #232c35;
	text-indent: 0;
	font-size: 5rem;
}
.content-slider .slick-arrow:hover:before {
	color: #000;
}
.content-slider .slick-arrow.slick-prev {
	left: 0;
}
.content-slider .slick-arrow.slick-prev:before {
	content: "\e902";
}
.content-slider .slick-arrow.slick-next {
	right: 0;
}
.content-slider .slick-arrow.slick-next:before {
	content: "\e903";
}


/* BP 2 */
@media screen and (min-width: $bp-769) {
	.content-slider .slick-slide:before {
		content: " ";
		background-color: rgba(194,189,189,0.6);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		-webkit-transition: all 400ms ease;
		-o-transition: all 400ms ease;
		transition: all 400ms ease;
	}
	.content-slider .slick-slide.slick-current:before {
		background-color: transparent;
	}
	.content-slider .slick-list {
		margin-left: calc((100% - 670px) / 2);
		overflow: visible;
	}
	.content-slider__item__img {
		max-width: 670px;
	}
	.content-slider .slick-arrow.slick-prev {
		left: calc((100% - 670px) / 2);
		margin-left: -45px;
	}
	.content-slider .slick-arrow.slick-next {
		right: calc((100% - 670px) / 2);
		margin-right: -45px;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	.content-slider .slick-list {
		margin-left: calc((100% - 840px) / 2);
	}
	.content-slider .slick-arrow.slick-prev {
		left: calc((100% - 840px) / 2);
	}
	.content-slider .slick-arrow.slick-next {
		right: calc((100% - 840px) / 2);
	}
	.content-slider__item__img {
		max-width: 840px;
	}
}


/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.content-slider .slick-arrow {
		width: 60px;
	}
	.content-slider .slick-arrow:before {
		font-size: 6rem;
	}
	.content-slider .slick-list {
		margin-left: calc((100% - 1140px) / 2);
	}
	.content-slider .slick-arrow.slick-prev {
		left: calc((100% - 1140px) / 2);
		margin-left: -50px;
	}
	.content-slider .slick-arrow.slick-next {
		right: calc((100% - 1140px) / 2);
		margin-right: -50px;
	}
	.content-slider__item__img {
		max-width: 787px;
	}
}


/* Extra BP */
@media screen and (min-width: 1340px) {
	.content-slider .slick-arrow:before {
		font-size: 8rem;
	}
	.content-slider .slick-arrow.slick-prev {
		margin-left: -100px;
	}
	.content-slider .slick-arrow.slick-next {
		margin-right: -100px;
	}
}

/* ---- Pre footer ---- */
.pre-footer__link-lists {
	display: none;
}

/* Location lists */
.location-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.location-list__item {
	margin: 0 0 29px;
}

.location-list__item__header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	    -ms-flex-align: start;
	        align-items: flex-start;
	margin: 9px 0 10px;
}
.location-list__item__title {
		-webkit-box-flex: 1;
	-webkit-flex: 1 1 auto;
	    -ms-flex: 1 1 auto;
	        flex: 1 1 auto;
	margin: 0;
}
.location-list__item__button {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	    -ms-flex: 0 0 auto;
	        flex: 0 0 auto;
	margin: -1px 0 0 15px;
}
.location-list__item__text__link {
	text-decoration: none;
	text-transform: uppercase;
}
.location-list__item__text__link:hover {
	text-decoration: underline;
}



/* BP 1 */
@media screen and (min-width: $bp-600) {

}

/* BP 2 */
@media screen and (min-width: $bp-769) {

}

/* BP 3 */
@media screen and (min-width: $bp-940) {

	.location-list__item__header {
		margin-top: 0px;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.section--pre-footer {
		padding-top: 80px;
		padding-bottom: 0;
	}
	.pre-footer__link-lists {
		display: flex;
		padding-left: 3.75%;
		font-size: 2rem;

		.h3 {
			font-size: 3rem;
		}

		& > .col-xs-12 {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
		}

		.stacked-buttons {

		}
	}



}

/* ---- Partners section ---- */
.partners-list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-flex-wrap: wrap;
	    -ms-flex-wrap: wrap;
	        flex-wrap: wrap;
}
.partners-list__item {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 33.3333333%;
	    -ms-flex: 1 1 33.3333333%;
	        flex: 1 1 33.3333333%;
	text-align: center;
	padding: 6px 0;
}
.partners-list__item a {
	display: block;
}
.partners-list__item a:hover {
	opacity: 0.9;
}
.partners-list__item img {
	max-width: 55px;
	max-height: 25px;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {
	.partners-list__item {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 auto;
		    -ms-flex: 1 1 auto;
		        flex: 1 1 auto;
		padding: 0;
	}
	.partners-list__item img {
		max-width: 64px;
		max-height: 30px;
	}
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.partners-list__item img {
		max-width: 74px;
		max-height: 40px;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	.partners-list__item img {
		max-width: 91px;
		max-height: 60px;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.partners-list__item img {
		max-width: 140px;
		max-height: 80px;
	}
}

/* ---- Products ---- */
.section--products {
	padding-bottom: 0;
}

/* No title */
.section--products.section--products--no-title {
	padding-top: 0;
}
.section--products.section--products--no-title .feature-box-list {
	margin-top: 0;
}



@import "02-layout/_media-objects";


@import "02-layout/_feature-boxes";


/* ---- About ---- */
.section--about {
	background: url(../images/img_about-bg.jpg) no-repeat center center;
	background-size: cover;
	padding: 6%;
	@media screen and (min-width: $bp-1230) {
		padding-left: 0;
	}

}

.about__content {
	background: rgba($color: #ffffff, $alpha: 0.8);
}
.about__highlight {
	padding: 15px 20px;
	height: 100%;
}
.about__highlight h1:first-child,
.about__highlight .h1:first-child,
.about__highlight h2:first-child,
.about__highlight .h2:first-child {
	margin-top: 0;
}
.about__content {
	padding: 22px 0;
	position: relative;

	&__button {
		position: absolute;
		bottom: 0;
		right: 0;
	}
}


/* BP 3 */
@media screen and (min-width: $bp-940) {
	.about__content {
		max-width: 55%;
		padding: 70px 60px 70px 0;

		&__button {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.about__content {
		padding: 80px 60px 80px 0;
		.container {
			margin-right: 0;
		}

		.offset {
			padding-top: 20px;
			padding-left: 100px;
		}


	}
}

/* ---- Video ---- */
.section--video {
	padding-top: 0;
	padding-bottom: 0;
}

/* ---- icon-card list ---- */
.icon-card-list {
	list-style: none;
	padding: 0;
	margin-top: 20px;
	row-gap: 2rem;
}
.icon-card {
	text-align: center;
	height: 100%;
	margin: auto;
	display: flex;
	flex-flow: column nowrap;
	font-size: 2rem;

	@media screen and (min-width: $bp-600) and (max-width: $bp-768) {
		font-size: 2.4rem;
	}

	&__text-wrap {
		flex: 1 1 auto;
		border: 1px solid $color-grey-02;
		border-bottom: 0px;
		padding: 8vw 6vw 10px 6vw;

		@media screen and (min-width: $bp-600) and (max-width: $bp-768) {
			padding: 60px 80px 10px;
		}

		@media screen and (min-width: $bp-940){
			padding: 60px 30px 10px;
		}

	}
}
.icon-card__icon {

}
.icon-card__title {
	font-size: 3.6rem;
}
.icon-card__title:after {
	margin-left: auto;
	margin-right: auto;
}

.icon-card__cta {
	width: 100%;
}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.icon-card {
	}
	.icon-card-list {
		margin-bottom: 30px;
		margin-top: 40px;
	}

}




/* ---- Promotion gallery ---- */

.promo-gallery {
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
}

.promo-gallery-single-item {
    padding: 15px;
    margin-bottom: 30px;
    text-align: center;
    background: #f1eeee;
    border: 1px solid #ffffff;
    border-bottom: 1px solid #e4dede;
    border-right: 1px solid #e4dede;
    transition: background 200ms ease-in-out;
}
.promo-gallery-single-item__link {
    display: inline-block;
    position: relative;
}

.promo-gallery__item {
    float: left;
    width: 50%;
    padding: 15px;
    text-align: center;
    background: #f1eeee;
    border: 1px solid #ffffff;
    border-bottom: 1px solid #e4dede;
    border-right: 1px solid #e4dede;
    transition: background 200ms ease-in-out;
}

.promo-gallery__item:nth-child(2n+3) {
    clear: left;
}

.promo-gallery__item-link {
    position: relative;
    display: inline-block;
}

.promo-gallery-single-item:hover,
.promo-gallery-single-item:focus,
.promo-gallery-single-item:active,
.promo-gallery__item:hover,
.promo-gallery__item:focus,
.promo-gallery__item:active {
    background: #e8e4e4;
}

.promo-gallery__item-hover:after {
    background: rgba(35,44,53,.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    font-size: 55px;
}

.promo-gallery__item__thumb {
    max-height: 300px;
}

@supports (display: flex) {
    .promo-gallery {
        display: flex;
        flex-flow: row wrap;
        flex: 0 1 auto;
    }
    .promo-gallery__item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .promo-gallery__item-hover:after {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 48em) {/*  768px */
    .promo-gallery__item-hover:after {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }
    .promo-gallery-single-item:hover .promo-gallery__item-hover:after,
    .promo-gallery-single-item:focus .promo-gallery__item-hover:after,
    .promo-gallery-single-item:active .promo-gallery__item-hover:after,
    .promo-gallery__item:hover .promo-gallery__item-hover:after,
    .promo-gallery__item:focus .promo-gallery__item-hover:after,
    .promo-gallery__item:active .promo-gallery__item-hover:after {
        opacity: 1;
    }
}




/* ---- Footer ---- */
.section--footer {
	padding-top: 13px;
	padding-bottom: 45px;
}
.footer-copyright {
	color: $color-brand-red-light;
	margin: 0;
	padding: 0;
	font-family: $alternate-font;
	font-weight: bold;
	font-size: 1.2rem;
	text-transform: uppercase;
}
.footer-nav {
	display: none;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {

}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.footer-copyright {
		padding: 6px 0;
		font-size: 1.4rem;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {

}

/* BP 4 */
@media screen and (min-width: $bp-1230) {
	.footer-nav {
		display: block;
	}
	.footer-copyright {
		padding: 12px 0;
		font-size: 1.4rem;
	}
}


/* ---- Magnific Popup ---- */
.mfp-image-holder .mfp-content {
	max-width: 1000px;
}

.mfp-ajax-holder .mfp-content {
	background-color: #fff;
	margin: 0 auto;
	padding: 20px 30px 25px 25px;
	max-width: 700px;
}

@media screen and (min-width: 48em) {/*  768px */
    .mfp-ajax-holder .mfp-content {
    	padding: 50px;
    }
}

.mfp-fade.mfp-bg {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	-o-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.15s ease-out;
	-o-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
}



/* ------------------------------------------------------
**** Widgets

Widgets & Modules that ship with sprout.
If they don't they belong in separate partial file

------------------------------------------------------ */

/* ---- Related Links ---- */

.widget-RelatedLinks ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.widget-RelatedLinks a {
	text-decoration: none;
	color: #232c35;
	display: block;
	padding: 9px 0;
}


/* Depth 1 */
.widget-RelatedLinks li.depth1 {
	border-bottom: 1px solid #e6e1e1;
	position: relative;
}
.widget-RelatedLinks li.depth1:last-child {
	border-bottom: none;
}
.widget-RelatedLinks li.depth1 > a {
	text-transform: uppercase;
	padding: 4px 0;
	font-family: $display-font;
	font-weight: 300;
	font-size: 1.7rem;
	line-height: 1.3;
}

/* Hover */
.widget-RelatedLinks li.depth1 > a:hover {

}

/* On */
.widget-RelatedLinks li.depth1.on > a {
	border-left: 3px solid #232c35;
	padding-left: 13px;
}



/* Children */
.widget-RelatedLinks li.depth2 {
	font-size: 1.4rem;
}
.widget-RelatedLinks li.depth2 a:before {
	content: "- ";
}
.widget-RelatedLinks li.depth2 a {
	padding: 3px 0 3px 24px;
}

/* Hover */
.widget-RelatedLinks li > a:hover {
	background-color: #eae8e8;
}

/* On */
.widget-RelatedLinks li.depth2.on > a {
	color: $color-brand-red;
	font-weight: bold;
}

/* BP 1 */
@media screen and (min-width: $bp-600) {

}

/* BP 2 */
@media screen and (min-width: $bp-769) {
	.widget-RelatedLinks li.depth1 > a {
		padding: 5px 0;
		font-size: 1.8rem;
	}
	.widget-RelatedLinks li.depth1.on > a {
		padding-left: 20px;
	}
}

/* BP 3 */
@media screen and (min-width: $bp-940) {
	.widget-RelatedLinks li.depth1 > a {
		padding: 9px 0;
		font-size: 2rem;
	}
}

/* BP 4 */
@media screen and (min-width: $bp-1230) {

}



/* ---- Simple Image Gallery ---- */
.widget-GallerySimple {
	margin: 15px -10px;
}

.widget-GallerySimple h3 {
	padding: 10px;
}

.widget-GallerySimple .gallery-simple-thumb {
	width: 33.333%;
	padding: 10px;
	float: left;
}
.widget-GallerySimple .gallery-simple-thumb img {
	width: 100%;
}

/* ---- Children Page Gallery ---- */
.children-gallery-list {
	list-style: none;
	margin: 22px 0 20px;
	padding: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-flow: row wrap;
	    -ms-flex-flow: row wrap;
	        flex-flow: row wrap;
}

.widget-ChildrenGallery {
margin-bottom: 30px;
}

.widget-ChildrenGallery .children-gallery-list-item {
	width: 50%;
	float: left;
	margin: 0;
	position: relative;
	background: #f1eeee;
	overflow: hidden;

	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	    -webkit-flex: 0 0 50%;
	        flex: 0 0 50%;
}

.widget-ChildrenGallery .children-gallery-list-item-link {
	display: block;
	text-decoration: none;
	color: inherit;
	position: relative;
	height: 100%;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover {
	text-decoration: none;
}

.widget-ChildrenGallery .children-gallery-list-item-image {
	margin-bottom: 0;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image {
	opacity: 0.9;
}

.widget-ChildrenGallery .children-gallery-list-item-title {
	border-left: 1px solid #e4dede;
	border-right: 1px solid #fff;
	margin: 0;
	padding: 12px 15px 15px;
	line-height: 1.1;
	text-transform: uppercase;
	font-family: $display-font;
	font-weight: 300;
	height: 100%;
}

.widget-ChildrenGallery .children-gallery-list-item-title:after {
	content: '\00a0 \003e';
	color: #9ba7b2;
	font-weight: bold;
	font-size: 2rem;
}

.widget-ChildrenGallery .children-gallery-list-item-link:after {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding-bottom: 30px;
	content: '\003e';
	text-align: center;
	color: #ffffff;
	font-size: 4rem;
	background: $color-brand-red;
	opacity: 0;
	-webkit-transition: opacity 200ms ease-in-out;
	-o-transition: opacity 200ms ease-in-out;
	transition: opacity 200ms ease-in-out;

	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-flow: column wrap;
	        -webkit-flex-flow: column wrap;
	        flex-flow: column wrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	        -webkit-justify-content: center;
	        justify-content: center;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover:after {
	opacity: 1;
}

@media screen and (min-width: $bp-600) {/*  600px */

	.widget-ChildrenGallery .children-gallery-list-item {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.33%;
		    -webkit-flex: 0 0 33.33%;
		        flex: 0 0 33.33%;
	}
}


@media screen and (min-width: 76.875em) {/*  1230px */

	.widget-ChildrenGallery .children-gallery-list-item {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		    -webkit-flex: 0 0 25%;
		        flex: 0 0 25%;
	}
}

/* --- float fallbacks --- */
@media screen and (max-width: 599px) {
	.no-flexbox .widget-ChildrenGallery .children-gallery-list-item:nth-child(2n+1) {
		margin-left: 0;
		clear: left;
	}
}

@media screen and (min-width: $bp-600) and (max-width: 768px) {
	.no-flexbox .widget-ChildrenGallery .children-gallery-list-item {
		width: 30.666666%;
	}
	.no-flexbox .widget-ChildrenGallery .children-gallery-list-item:nth-child(3n+1) {
		margin-left: 0;
		clear: left;
	}
}

@media screen and (min-width: $bp-769) {
	.no-flexbox .widget-ChildrenGallery .children-gallery-list-item {
		width: 22%;
	}
	.no-flexbox .widget-ChildrenGallery .children-gallery-list-item:nth-child(4n+1) {
		margin-left: 0;
		clear: left;
	}
}


/* ---- Share links ---- */
.share-links {
	margin: 0 0 20px;
}
.share-link {
	display: inline-block;
	margin: 0 5px 0 0;
}
.share-link:last-child {
	margin-right: 0;
}
.share-link a {
	display: block;
	width: 40px;
	height: 40px;
	background-color: #000;
	border-radius: 50%;
	text-align: center;
	padding: 5px;
	position: relative;
}
.share-link img {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	-webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	max-width: 22px;
}
.share-link a:hover {
	opacity: 0.9;
}


/* Facebook */
.share-link.share-link--facebook a {
	background-color: #3e5b98;
}

/* Twitter */
.share-link.share-link--twitter a {
	background-color: #4da7de;
}

/* Email */
.share-link.share-link--email a {
	background-color: #444;
}

/* LinkedIn */
.share-link.share-link--linkedin a {
	background-color: #3371b7;
}

/* ---- Sponsors module ---- */

.sponsors-list__item-link {
	text-decoration: none;
}

.sponsors-list__img-wrap {
	width: 100%;
	border: 1px solid #ccc;
	padding: 8px;
	position: relative;
	margin-bottom: 8px;
	-webkit-transition: border-color 250ms ease-in-out;
	-o-transition: border-color 250ms ease-in-out;
	transition: border-color 250ms ease-in-out;
}

.sponsors-list__item-link:hover .sponsors-list__img-wrap {
	border: 1px solid #444;
}

.sponsors-list__img-wrap:before {
	content: "";
	display: block;
	padding-top: 100%; 	/* initial ratio of 1:1*/
}

.sponsors-list__img-wrap__vert-center {
	position: absolute;
	top: 10px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	text-align: center;
	align-items: center;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
}

.sponsors-list-item__image {
	display: inline-block;
	margin: 0 auto;
	max-height: 100%;
}




/* ---- Order Form ---- */
.widget-OrderForm .product {
	width: 30%;
	margin-right: 5%;
	float: left;
}
.widget-OrderForm .product:nth-child(3n+3) {
	margin-right: 0;
}

/* ---- Videos ---- */
.widget-Video {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px; height: 0; overflow: hidden;
}

.widget-Video iframe,
.widget-Video object,
.widget-Video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* ---- reCAPTCHA ---- */
.g-recaptcha {
	padding-bottom: 15px;
}


/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */

@media print {
	* {
		background: transparent !important;
		color: #000 !important; /* Black prints faster: h5bp.com/s */
		-webkit-box-shadow: none !important;
		        box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	/* Don't show links for images, or javascript/internal links */

	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; /* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 0.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}


/* ---- TinyMCE Templates ---- */

/* These styles are for client use in the TinyMCE Template system */
/* prefixed with ct- (content template) to avoid clashing with skin template columns */

.ct-col-group {
}

/* two columns */
@media screen and (min-width: 500px) {
	.ct-col--one-half {
		float: left;
		width: 48.148148148148%;
		margin-left: 3.561253561254%;
	}
	.ct-col--one-half:first-child {
		margin-left: 0;
	}
}

/* three columns */
@media screen and (min-width: 560px) {
	.ct-col--one-third {
		float: left;
		width: 30.95916429249733%;
		margin-left: 3.561253561254%;
	}
	.ct-col--one-third:nth-child(3n+1),
	.ct-col--one-third:first-child {
		margin-left: 0;
	}
}
